import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { APIget, APIputjson } from '../../apiCore';
import { Loading, Notify } from '../global-components/messaging';
import { isAuthenticated } from '../../auth';

const BootcampDetails = () => {
	let imgsrc = `${process.env.REACT_APP_PUBLIC_PORT}images/`;
	let usrimgsrc = `${process.env.REACT_APP_PUBLIC_PORT}images/`;
	// let imgsrc = publicUrl + 'assets/img/bootcamp/';

	let numberAttending;

	const [values, setValues] = useState({
		bootcamp: {},
		error: '',
		message: '',
		attending: false,
		success: false,
		loading: true,
	});

	// let usrimgsrc = publicUrl + 'assets/img/author/';
	// if (values.bootcamp.trainer.photo !== 'user-no-photo.jpg')
	// 	usrimgsrc = `${process.env.REACT_APP_PUBLIC_PORT}images/`;
	// console.log(usrimgsrc);
	const lsUser = JSON.parse(localStorage.getItem('user'));
	const location = useLocation();
	const locationString = String(location.pathname);
	const id = locationString.replace('/bootcamp/', '');

	const APICall = async () => {
		setValues({ ...values, loading: true });
		const qstr = ``;
		await APIget(`bootcamps/${id}`, qstr).then((data) => {
			console.log('Data recieved from bootcamps');
			console.log(data.data);
			if (data.error)
				return setValues({ ...values, error: data.error, success: false });
			if (data.data.count !== 0) {
				setValues({
					...values,
					bootcamp: data.data.data,
					loading: false,
				});
			} else {
				setValues({
					...values,
					loading: false,
				});
			}
		});
	};

	useEffect(() => {
		loadBootcamp();
	}, []);

	const loadBootcamp = async () => {
		await APICall();
	};

	const isAttending = () => {
		let attending = false;
		if (!isAuthenticated()) {
			setValues({ ...values, error: 'Please Sign In first' });
		}

		if (values.bootcamp.attending !== undefined || 0) {
			if (
				values.bootcamp.attending.indexOf(
					`${String(lsUser.loggedInUser.email)}`
				) !== -1
			) {
				console.log('Yes, the value exists!');
			} else {
				console.log('No, the value is absent.');
			}
			attending = values.bootcamp.attending.includes(
				`${String(lsUser.loggedInUser.email)}`
			);

			console.log(
				values.bootcamp.attending.includes(`${lsUser.loggedInUser.email}`)
			);

			values.bootcamp.attending.forEach((val) => {
				if (val === lsUser.loggedInUser.email) return (attending = true);
			});
			console.log(`Is Attending? ${attending}`);
		}

		return attending;
	};

	const handleAttending = () => {
		console.log('Handling Attendance');
		let isattending;

		console.log(values.bootcamp.attending);
		if (isAuthenticated()) {
			if (values.bootcamp.attending !== undefined || 0) {
				isattending = values.bootcamp.attending.includes(
					lsUser.loggedInUser.email
				);

				console.log(isattending);

				if (isattending) {
					console.log('User Already Attending');
					const index = values.bootcamp.attending.indexOf(
						lsUser.loggedInUser.email
					);
					const x = values.bootcamp.attending.splice(index, 1);

					console.log(`myArray values: ${values.bootcamp.attending}`);
					console.log(`variable x value: ${x}`);
				} else {
					values.bootcamp.attending = [
						...values.bootcamp.attending,
						lsUser.loggedInUser.email,
					];
				}
			} else {
				console.log('User Currently Not Attending');
				let newAttendance = [];
				values.bootcamp.attending = [
					...newAttendance,
					lsUser.loggedInUser.email,
				];
				console.log(values.bootcamp);
			}

			setValues({ ...values, message: 'Updating' });

			console.log('Bootcamp Attending');
			console.log(values.bootcamp.attending);

			const reqData = { attending: values.bootcamp.attending };

			console.log('This is the request data: ');
			console.log(reqData);

			console.log('This is the Bootcamps state before API request: ');
			console.log(values.bootcamp);

			let { attending } = values.bootcamp;

			APIputjson(`bootcamps/attend/${values.bootcamp._id}`, {
				attending,
			}).then((response) => {
				console.log(response);
				if (response.error) {
					return setValues({
						...values,
						error: response.error,
						success: false,
					});
				}

				console.log('Response Data');
				console.log(response.data.data);

				setValues({
					...values,
					loading: false,
					success: true,
					bootcamp: response.data.data,
					message: response.data.message,
				});
			});

			console.log('This is the Bootcamps state after API request: ');
			console.log(values.bootcamp);
		} else {
			setValues({ ...values, error: 'Please Sign In first' });
		}
	};

	if (values.bootcamp.attending !== undefined)
		numberAttending = values.bootcamp.attending.length;

	console.log(values.bootcamp);

	// if (values.bootcamp.photo !== undefined)
	// 	console.log(values.bootcamp.photo);
	// if (values.bootcamp.photo !== 'no-photo.jpg' || undefined) {
	// 	console.log('There is a picture');
	// 	imgsrc = `${process.env.REACT_APP_PUBLIC_PORT}images/`;
	// 	console.log(imgsrc);
	// }

	const isbootcamp = () => {
		console.log(values.bootcamp);
		let isThere = false;
		if (values.bootcamp) {
			return (isThere = true);
		}
		return isThere;
	};

	const canUpload = () => {
		const lsUser = JSON.parse(localStorage.getItem('user'));
		let can = false;
		if (lsUser) {
			if (lsUser.loggedInUser.role !== 'USER') can = true;
		}
		return can;
	};

	const showError = () => (
		<div
			className="alert alert-danger center"
			style={{ display: values.error ? '' : 'none' }}>
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{values.error}
		</div>
	);

	const close = () => {
		setValues({ ...values, success: false, error: '' });
	};

	const showSuccess = () => (
		<div
			style={{ display: values.success ? '' : 'none' }}
			className="alert alert-info center">
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{values.message}
		</div>
	);

	return (
		<div className="course-single-area pd-top-120 pd-bottom-90">
			<div className="container">
				{showError()}
				{showSuccess()}
				{values.loading ? (
					<Loading loadingText="Checking Events" />
				) : (
					<div>
						{isbootcamp() ? (
							<div className="row">
								<div className="col-lg-8">
									<div className="course-course-detaila-inner">
										<div className="details-inner">
											<div className="emt-user">
												<span className="u-thumb">
													<img
														src={usrimgsrc + values.bootcamp.trainer.photo}
														alt="img"
													/>
												</span>
												<span className="align-self-center">
													<Link
														to={`/trainer-details/${values.bootcamp.trainer._id}`}>
														{values.bootcamp.trainer.name}{' '}
														{values.bootcamp.trainer.surname}
													</Link>
												</span>
											</div>
											<h3 className="title">
												<a href="course-details.html">{values.bootcamp.name}</a>
											</h3>
										</div>
										<div className="thumb">
											<img
												// src={publicUrl + 'assets/img/bootcamp/9.png'}

												src={imgsrc + values.bootcamp.photo}
												alt="img"
											/>
										</div>
										{canUpload() && (
											<Link
												to={`/upload/bootcamps/photo/${values.bootcamp._id}`}>
												<button className="btn btn-base w-100 ">
													Upload New Event Image
												</button>
											</Link>
										)}
										<div className="course-details-nav-tab text-center mt-3">
											<ul
												className="nav nav-tabs"
												id="myTab"
												role="tablist">
												<li className="nav-item">
													<a
														className="nav-link active"
														id="tab1-tab"
														data-toggle="tab"
														href="#tab1"
														role="tab"
														aria-controls="tab1"
														aria-selected="true">
														Description
													</a>
												</li>
											</ul>
										</div>
										<div
											className="tab-content"
											id="myTabContent">
											<div
												className="tab-pane fade show active"
												id="tab1"
												role="tabpanel"
												aria-labelledby="tab1-tab">
												<div className="course-details-content">
													<p>{values.bootcamp.description}</p>
													{/* <div className="row pt-4">
														<div className="col-sm-6">
															<ul className="single-list-wrap">
																<li className="single-list-inner style-check-box">
																	<i className="fa fa-check" /> Metus interdum
																	metus
																</li>
																<li className="single-list-inner style-check-box">
																	<i className="fa fa-check" /> Ligula cur
																	maecenas
																</li>
																<li className="single-list-inner style-check-box">
																	<i className="fa fa-check" /> Fringilla nulla
																</li>
															</ul>
														</div>
														<div className="col-sm-6 mt-3 mt-sm-0">
															<ul className="single-list-wrap">
																<li className="single-list-inner style-check-box">
																	<i className="fa fa-check" /> Metus interdum
																	metus
																</li>
																<li className="single-list-inner style-check-box">
																	<i className="fa fa-check" /> Ligula cur
																	maecenas
																</li>
																<li className="single-list-inner style-check-box">
																	<i className="fa fa-check" /> Fringilla nulla
																</li>
															</ul>
														</div>
													</div> */}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="td-sidebar">
										<div className="widget widget_feature">
											<h4 className="widget-title">Event Details</h4>
											<ul>
												<li>
													<i className="fa fa-clipboard" />
													<span className="bold">Instructor:</span>{' '}
													{values.bootcamp.trainer.name}{' '}
													{values.bootcamp.trainer.surname}
												</li>
												<li>
													<i className="fa fa-calendar" />
													Start Date: {values.bootcamp.dateStart}
												</li>
												<li>
													<i className="fa fa-clock-o" />
													Start Time: {values.bootcamp.timeStart} hrs
												</li>
												<li>
													<i className="fa fa-calendar" />
													End Date: {values.bootcamp.dateEnd}
												</li>
												<li>
													<i className="fa fa-clock-o" />
													End Time: {values.bootcamp.timeEnd} hrs
												</li>
												<li>
													<i className="fa fa-ticket" />
													Number of Participants: {numberAttending || 14}
												</li>
												<li>
													<i className="fa fa-map-marker" />
													Location: {values.bootcamp.location}
												</li>
												<li>
													<i className="fa fa-clone" />
													<span>Careers: </span>{' '}
													{values.bootcamp.careers.map((career) => (
														<span key={career}>{career + ', '}</span>
													))}
												</li>
												<li>
													<i className="fa fa-tags" />
													<span>Tags:</span> {values.bootcamp.tags}
												</li>
											</ul>
											<div className="price-wrap text-center">
												<h5>
													Price:<span>${values.bootcamp.cost}.00</span>
												</h5>
												{isAttending() ? (
													<a
														className="btn btn-base"
														onClick={handleAttending}>
														I CAN'T ATTEND ANYMORE
													</a>
												) : (
													<a
														className="btn btn-base"
														onClick={handleAttending}>
														RESERVE PLACE FOR ME
													</a>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							<Notify message={'No Bootcamps to show.'} />
						)}
					</div>
				)}
				{}
			</div>
		</div>
	);
};

export default BootcampDetails;
