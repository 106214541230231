import React, { useState } from 'react';
import { APIpost } from '../../apiCore';
import { Link, Redirect } from 'react-router-dom';
import parse from 'html-react-parser';

const CreateBootcamp = () => {
	// console.log(`API Location: ${process.env.REACT_APP_API_PORT}`);
	const [values, setValues] = useState({
		jobId: '',
		name: '',
		description: '',
		location: '',
		applicationDetails: '',
		closingDate: '',
		company: '',
		timeEnd: '',
		fields: '',
		error: '',
		successMessage: '',
		success: false,
		loading: false,
		redirectToReferrer: false,
	});

	const handleChange = (name) => (e) => {
		setValues({ ...values, error: false, [name]: e.target.value });
	};

	let {
		jobId,
		name,
		description,
		location,
		applicationDetails,
		closingDate,
		company,
		fields,
		error,
		successMessage,
		success,
		loading,
		redirectToReferrer,
	} = values;

	// console.log(values);

	const clickSubmit = async (event) => {
		event.preventDefault();
		console.log(fields);

		fields = fields.split(',');
		console.log(fields);
		console.log('Click SUbmit');

		await APIpost('jobs', {
			name,
			description,
			location,
			applicationDetails,
			closingDate,
			company,
			fields,
		}).then((response) => {
			console.log(response);
			if (response.error) {
				return setValues({ ...values, error: response.error, success: false });
			}

			setValues({
				...values,
				jobId: response.data.data._id,
				successMessage: response.data.message,
				success: true,
				loading: false,
				redirectToReferrer: true,
			});
		});
	};

	const showError = () => (
		<div
			className="alert alert-danger center"
			style={{ display: error ? '' : 'none' }}>
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{error}
		</div>
	);

	const close = () => {
		setValues({ ...values, success: false, error: '' });
	};

	const showSuccess = () => (
		<div
			style={{ display: success ? '' : 'none' }}
			className="alert alert-info center">
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{successMessage}
		</div>
	);

	const showLoading = () =>
		loading && (
			<div
				className="alert alert-info center"
				style={{ display: success ? '' : 'none' }}>
				<div>Creating New Job...</div>
			</div>
		);

	const redirectUser = () => {
		if (redirectToReferrer) {
			return <Redirect to={'/job'} />;
		}
	};

	console.log(values);

	return (
		<div className="signup-page-area pd-top-120 pd-bottom-120">
			<div className="container">
				<div className="row justify-content-center">
					{showLoading()}
					{showSuccess()}
					{showError()}
					{redirectUser()}
					<div className="col-xl-6 col-lg-7">
						<form className="signin-inner">
							<div className="row">
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('name')}
											type="text"
											placeholder="Job Name"
											value={name}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<textarea
											onChange={handleChange('description')}
											type="text"
											placeholder="Job description"
											value={description}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('company')}
											type="text"
											placeholder="Company"
											value={company}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<textarea
											onChange={handleChange('applicationDetails')}
											type="text"
											placeholder="Application Details"
											value={applicationDetails}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<textarea
											onChange={handleChange('fields')}
											type="text"
											placeholder="Fields open to...Separate with comma (,)"
											value={fields}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('location')}
											type="text"
											placeholder="Location of Job"
											value={location}
										/>
									</div>
								</div>

								<p>Closing date:</p>

								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('closingDate')}
											type="date"
											id="closingDate"
											name="closingDate"
											placeholder="Closing Date"
											value={closingDate}
										/>
									</div>
								</div>

								<div className="col-12 mb-4">
									<button
										onClick={clickSubmit}
										className="btn btn-base w-100">
										Create Job
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateBootcamp;
