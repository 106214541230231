import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { signin, userSave } from '../../auth';

const Signin = () => {
	const [values, setValues] = useState({
		email: 'kcncube@gmail.com',
		password: '12345',
		error: '',
		showPassword: false,
		loading: false,
		redirectToReferrer: false,
	});

	const handleChange = (name) => (e) => {
		setValues({ ...values, error: false, [name]: e.target.value });
	};

	const { email, password, error, success, loading, redirectToReferrer } =
		values;

	// console.log(values);

	const clickSubmit = async (event) => {
		event.preventDefault();
		await setValues({ ...values, error: false, loading: true });
		await signin(email, password).then((data) => {
			console.log(data);
			if (data === 'Network Error')
				return setValues({ ...values, error: 'Network Error', loading: false });
			if (data.error) {
				return setValues({ ...values, error: data.error, loading: false });
			}

			console.log(`Saving data ${data.data}`);
			userSave(data.data, () => {
				setValues({
					...values,
					redirectToReferrer: true,
				});
			});
		});

		// console.log(response);

		// console.log(`Making a request to: ${process.env.REACT_APP_API_PORT}`);
	};

	const showError = () => (
		<div
			className="alert alert-danger center"
			style={{ display: error ? '' : 'none' }}>
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{error}
		</div>
	);

	const showLoading = () =>
		loading && (
			<div style={{ display: success ? '' : 'none' }}>
				<div className="alert alert-info center">Signing in...</div>
				{/* <div className="center">
				<Link to="/bootcamps">Check out the bootcamps</Link>
			</div> */}
			</div>
		);

	const close = () => {
		setValues({ ...values, success: false, error: '' });
	};

	const redirectUser = () => {
		if (redirectToReferrer) {
			return <Redirect to="/bootcamps" />;
		}
	};

	const handleShowPassword = async (event) => {
		if (event.target.checked) {
			setValues({ ...values, showPassword: true });
		} else {
			setValues({ ...values, showPassword: false });
		}
	};

	return (
		<div className="signin-page-area pd-top-120 pd-bottom-120">
			<div className="container">
				<div className="row justify-content-center">
					{showLoading()}
					{showError()}
					<div className="col-xl-6 col-lg-7">
						<form className="signin-inner">
							<div className="row">
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('email')}
											type="text"
											placeholder="Email"
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('password')}
											type={values.showPassword ? 'text' : 'password'}
											placeholder="Password"
										/>
									</div>
								</div>
								<div className="col-12">
									<label className="single-checkbox">
										<input
											type="checkbox"
											name="Free"
											onChange={handleShowPassword}
											checked={values.free}
										/>
										<span className="checkmark" />
										Show password
									</label>
								</div>
								<div className="col-12 mb-4">
									<button
										onClick={clickSubmit}
										className="btn btn-base w-100">
										Sign In
									</button>
								</div>
								<div className="col-12">
									<Link to="/sign-up"> Don't have an account?</Link>
									<br></br>
									<Link to="/forgotpassword">Forgot password?</Link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			{redirectUser()}
		</div>
	);
};

export default Signin;
