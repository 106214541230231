import React from 'react';
import Navbar from './global-components/navbar-v1';
import PageHeader from './global-components/page-header';
import Trainer from './section-components/trainer-details';
import Footer from './global-components/footer-v2';

const TrainerPage = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="Trainer Details" />
			<Trainer />
			<Footer />
		</div>
	);
};

export default TrainerPage;
