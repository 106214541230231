import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route } from 'react-router-dom';

import Home from './components/home';
import Jobs from './components/jobs';
import Job from './components/job-details';
import BootcampDetails from './components/course-details';
import CreateBootcamp from './components/createBootcamp';
import CreateJob from './components/createjob';
import Upload from './components/upload';
import About from './components/about';
import Bootcamps from './components/bootcamps';
import EventDetails from './components/event-details';
import Trainers from './components/trainers';
import TrainerDetails from './components/trainerdetails';
import Users from './components/users';
import UserDetails from './components/userdetails';
import Pricing from './components/pricing';
import SignIn from './components/sign-in';
import ResetPassword from './components/reset-password';
import ForgotPassword from './components/forgot-password';
import SignUp from './components/sign-up';
import Modify from './components/modify';
import Contact from './components/contact';

class Root extends Component {
	render() {
		return (
			<HashRouter basename="/">
				<div>
					<Router>
						<Route
							exact
							path="/"
							component={Home}
						/>
						<Route
							path="/jobs"
							component={Jobs}
						/>
						<Route
							path="/job"
							component={Job}
						/>
						<Route
							path="/bootcamp"
							component={BootcampDetails}
						/>
						<Route
							path="/createbootcamp"
							component={CreateBootcamp}
						/>
						<Route
							path="/createjob"
							component={CreateJob}
						/>
						<Route
							path="/upload"
							component={Upload}
						/>
						<Route
							path="/about"
							component={About}
						/>
						<Route
							path="/bootcamps"
							component={Bootcamps}
						/>
						<Route
							path="/event-details"
							component={EventDetails}
						/>
						<Route
							path="/trainers"
							component={Trainers}
						/>
						<Route
							path="/trainer-details"
							component={TrainerDetails}
						/>
						<Route
							path="/users"
							component={Users}
						/>
						<Route
							path="/modify"
							component={Modify}
						/>
						<Route
							path="/user-details"
							component={UserDetails}
						/>
						<Route
							path="/pricing"
							component={Pricing}
						/>
						<Route
							path="/sign-in"
							component={SignIn}
						/>
						<Route
							path="/sign-up"
							component={SignUp}
						/>
						<Route
							path="/resetPassword"
							component={ResetPassword}
						/>
						<Route
							path="/forgotPassword"
							component={ForgotPassword}
						/>
						<Route
							path="/contact"
							component={Contact}
						/>
					</Router>
				</div>
			</HashRouter>
		);
	}
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('stemfactory'));
