import React from 'react';
import Navbar from './global-components/navbar-v1';
import PageHeader from './global-components/page-header';
import EventDetailsPage from './section-components/bootcamp';
import Footer from './global-components/footer-v2';

const EventDetails = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="Event Single" />
			<EventDetailsPage />
			<Footer />
		</div>
	);
};

export default EventDetails;
