import React, { useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { userSave, passwordReset } from '../../auth';

const ResetPassword = () => {
	const location = useLocation();
	const locationString = String(location.pathname);
	// const id = locationString.replace('/bootcamp/', '');
	console.log(locationString);
	const resetToken = locationString.replace('/resetpassword/', '');
	console.log(resetToken);

	const [values, setValues] = useState({
		password: '',
		passwordConfirm: '',
		error: '',
		showPassword: false,
		success: false,
		loading: false,
		redirectToReferrer: false,
	});

	const handleChange = (name) => (e) => {
		setValues({ ...values, error: false, [name]: e.target.value });
	};

	const {
		password,
		passwordConfirm,
		error,
		success,
		loading,
		redirectToReferrer,
	} = values;

	// console.log(values);

	const clickSubmit = async (event) => {
		event.preventDefault();
		setValues({ ...values, loading: true });
		if (password != passwordConfirm) {
			return setValues({
				...values,
				error: 'Passwords do not match',
			});
		}
		await passwordReset(password, resetToken).then((data) => {
			console.log(data);
			if (data.error) {
				return setValues({ ...values, error: data.error, success: false });
			}

			alert('Password has been changed');
			userSave(data.data, () => {
				setValues({
					...values,
					password: '',
					passwordConfirm: '',
					error: '',
					showPassword: false,
					success: true,
					loading: false,
					redirectToReferrer: true,
				});
			});
		});

		// console.log(response);

		// console.log(`Making a request to: ${process.env.REACT_APP_API_PORT}`);
	};

	const showError = () => (
		<div
			className="alert alert-danger center"
			style={{ display: error ? '' : 'none' }}>
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{error}
		</div>
	);

	const showLoading = () =>
		loading && (
			<div style={{ display: success ? '' : 'none' }}>
				<div className="alert alert-info center">Signing in...</div>
				{/* <div className="center">
				<Link to="/bootcamps">Check out the bootcamps</Link>
			</div> */}
			</div>
		);

	const close = () => {
		setValues({ ...values, success: false, error: '' });
	};

	const redirectUser = () => {
		if (redirectToReferrer) {
			return <Redirect to="/bootcamps" />;
		}
	};

	const handleShowPassword = async (event) => {
		if (event.target.checked) {
			setValues({ ...values, showPassword: true });
		} else {
			setValues({ ...values, showPassword: false });
		}
	};

	return (
		<div className="signin-page-area pd-top-120 pd-bottom-120">
			<div className="container">
				<div className="row justify-content-center">
					{showLoading()}
					{showError()}
					<div className="col-xl-6 col-lg-7">
						<form className="signin-inner">
							<div className="row">
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('password')}
											type={values.showPassword ? 'text' : 'password'}
											placeholder="New Password"
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('passwordConfirm')}
											type={values.showPassword ? 'text' : 'password'}
											placeholder="Repeat Password"
										/>
									</div>
								</div>
								<div className="col-12">
									<label className="single-checkbox">
										<input
											type="checkbox"
											name="Free"
											onChange={handleShowPassword}
											checked={values.free}
										/>
										<span className="checkmark" />
										Show password
									</label>
								</div>
								<div className="col-12 mb-4">
									<button
										onClick={clickSubmit}
										className="btn btn-base w-100">
										Submit
									</button>
								</div>
								<div className="col-12">
									<Link to="/sign-up"> Don't have an account?</Link>
									<br></br>
									<Link to="/sign-in">Have you remembered it? Login</Link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			{redirectUser()}
		</div>
	);
};

export default ResetPassword;
