import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { APIget } from '../../apiCore';
import TrainersCard from './trainersCard';
import { Notify, Loading } from '../global-components/messaging';

const Trainer = () => {
	let publicUrl = process.env.PUBLIC_URL + '/';

	const [values, setValues] = useState({
		search: '',
		loading: false,
		error: '',
		trainers: {},
		pagination: {},
		success: true,
	});

	const loadTrainer = async (page) => {
		console.log('Searching for Trainers');
		const pageSelect = `page=${page || 1}`;
		setValues({ ...values, loading: true });
		await APIget(
			'Users',
			`role=TRN&${pageSelect}&limit=6&search=${values.search}`
		).then((response) => {
			if (response.error)
				return setValues({ ...values, error: response.error, success: false });
			setValues({
				...values,
				loading: false,
				trainers: response.data.data,
				pagination: response.data.pagination,
			});
		});
	};

	const isTrainers = () => {
		let trainers = false;
		if (values.trainers.length !== undefined || 0) trainers = true;
		return trainers;
	};

	const handleSearchButton = () => {
		loadTrainer();
	};

	useEffect(() => {
		loadTrainer();
	}, []);

	const handleLastPage = () => {
		console.log(values.pagination.totalPages);
		setValues({ ...values, page: values.pagination.totalPages });
		loadTrainer(values.pagination.totalPages);
	};

	const handleNext = () => {
		if (values.pagination.next.page >= values.pagination.currentpage)
			loadTrainer(values.pagination.next.page);
	};

	const handlePrev = () => {
		console.log('Previous page');
		if (values.pagination.prev.page <= values.pagination.currentpage) {
			console.log('Able to go to Previous page');
			loadTrainer(values.pagination.prev.page);
		}
	};

	const handleChange = (name) => async (e) => {
		await setValues({ ...values, error: false, [name]: e.target.value });

		// await APICall();
	};

	const showError = () => (
		<div
			className="alert alert-danger center"
			style={{ display: values.error ? '' : 'none' }}>
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{values.error}
		</div>
	);

	const close = () => {
		setValues({ ...values, success: false, error: '' });
	};

	const showSuccess = () => (
		<div
			style={{ display: values.success ? '' : 'none' }}
			className="alert alert-info center">
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{values.message}
		</div>
	);

	console.log(values);

	return (
		<div className="team-area pd-top-120 pd-bottom-70 go-top">
			<div className="container">
				{showError()}
				{/* {showSuccess()} */}
				<form className="footer-subscribe-inner">
					<div className="row">
						<div className="col-lg-10">
							<div className="single-input-inner style-border-bottom">
								<input
									onChange={handleChange('search')}
									type="text"
									placeholder="Name of Trainer"
								/>
							</div>
						</div>
						<div className="col-lg-2">
							<a
								onClick={handleSearchButton}
								className="btn btn-base w-100">
								Search
							</a>
						</div>
					</div>
				</form>
				<div className="row justify-content-center mt-5">
					{values.loading ? (
						<Loading loadingText="Checking for Trainers" />
					) : isTrainers() ? (
						values.trainers.map((trainer, i) => (
							<TrainersCard
								key={i}
								trainer={trainer}
							/>
						))
					) : (
						<Notify message="No Trainers to Show" />
					)}
				</div>
				{isTrainers() && (
					<nav className="td-page-navigation center">
						<ul className="pagination">
							<li className="pagination-arrow">
								<button
									className="btn-pgn"
									onClick={handlePrev}>
									<i className="fa fa-angle-double-left" />
								</button>
							</li>
							<li>
								<button className="btn-pgn">
									{values.pagination.currentpage}
								</button>
							</li>
							<li>
								<button className="btn-pgn-uslss">/</button>
							</li>
							<li>
								<button
									className="btn-pgn"
									onClick={handleLastPage}>
									{values.pagination.totalPages}
								</button>
							</li>
							<li className="pagination-arrow">
								<button
									className="btn-pgn"
									onClick={handleNext}>
									<i className="fa fa-angle-double-right" />
								</button>
							</li>
						</ul>
					</nav>
				)}
			</div>
		</div>
	);
};

export default Trainer;
