import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { APIget } from '../../apiCore';

const Footer_v2 = () => {
	let publicUrl = process.env.PUBLIC_URL + '/';
	const minscript = document.createElement('script');
	minscript.async = true;
	minscript.src = publicUrl + 'assets/js/main.js';

	const upComing = JSON.parse(localStorage.getItem('events'));
	const [values, setValues] = useState({
		loading: false,
		events: {},
		success: false,
	});

	const showUpcomingEvents = () => {
		if (values.success && values.events.length !== 0) return true;
		return false;
	};

	const loadEvents = async () => {
		setValues({ ...values, loading: true });
		await APIget('bootcamps', 'limit=2').then((response) => {
			if (response.error)
				return setValues({ ...values, loading: false, success: false });

			setValues({
				...values,
				loading: false,
				events: response.data.data,
				success: true,
			});
			localStorage.setItem('events', JSON.stringify(response.data.data));
		});
	};

	useEffect(() => {
		if (!upComing) return loadEvents();
		setValues({ ...values, events: upComing, success: true });
	}, []);

	return (
		<footer className="footer-area footer-area-2 bg-gray">
			<div className="footer-top">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-md-6">
							<div className="widget widget_about text-center">
								<a href="/">
									<img
										src={publicUrl + 'assets/img/logo-3.png'}
										alt="img"
									/>
								</a>
								<div className="details">
									<p>
										The enabling environment for STEM graduates and qualified
										experts.
									</p>
									<ul className="social-media">
										<li>
											<a href="https://www.facebook.com/stemskillsfactory">
												<i className="fa fa-facebook" />
											</a>
										</li>
										{/* <li>
											<a href="https://www.twitter.com/">
												<i className="fa fa-twitter" />
											</a>
										</li> */}
										<li>
											<a href="https://www.instagram.com/stemskillsfactory/">
												<i className="fa fa-instagram" />
											</a>
										</li>
										<li>
											<a href="https://www.linkedin.com/company/stem-skills-factory">
												<i className="fa fa-linkedin" />
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="widget widget_contact">
								<h4 className="widget-title">Contact Us</h4>
								<ul className="details">
									<li>
										<i className="fa fa-map-marker" /> 4 Link Road, Mt Pleasant,
										Harare
									</li>
									<li>
										<i className="fa fa-envelope" /> info@stemskillsfactory.org
									</li>
									<li>
										<i className="fa fa-phone" /> +263 735 439 995
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="widget widget_blog_list">
								<h4 className="widget-title">Bootcamps &amp; Events</h4>
								{showUpcomingEvents() ? (
									<ul>
										{' '}
										{values.events.map((event, i) => (
											<li key={i}>
												<h6>
													<Link to={`/bootcamp/${event._id}`}>
														{event.name}
													</Link>
												</h6>
												<span className="date">
													<i className="fa fa-calendar" />
													{event.dateStart}
												</span>
											</li>
										))}
									</ul>
								) : (
									<ul>
										<li>
											<h6>
												<Link to="/blog-details">How to dress to impress</Link>
											</h6>
											<span className="date">
												<i className="fa fa-calendar" />
												January 20, 2022
											</span>
										</li>
										<li>
											<h6>
												<Link to="/blog-details">
													How to present your qualifications
												</Link>
											</h6>
											<span className="date">
												<i className="fa fa-calendar" />
												February 26, 2022
											</span>
										</li>
									</ul>
								)}
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="widget widget_nav_menu">
								<h4 className="widget-title">Jobs</h4>
								<ul className="go-top">
									<li>
										<Link to="/jobs">Science</Link>
									</li>
									<li>
										<Link to="/jobs">Technology</Link>
									</li>
									<li>
										<Link to="/jobs">Engineering</Link>
									</li>
									<li>
										<Link to="/jobs">Mathematics</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-bottom">
				<div className="container">
					<div className="row">
						<div className="col-md-5 align-self-center">
							<p>
								{' '}
								<i
									className="fa fa-copyright"
									aria-hidden="true"
								/>
								{'  '}Copyright 2023
							</p>
						</div>
						<div className="col-md-7 text-md-right align-self-center mt-md-0 mt-2">
							<div className="widget_nav_menu">
								<ul className="go-top">
									<li>
										<Link to="/">Home</Link>
									</li>
									<li>
										<Link to="/about">About Us</Link>
									</li>
									<li>
										<Link to="/pricing">Our Pricing</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer_v2;
