import React from 'react';
import Navbar from './global-components/navbar-v1';
import PageHeader from './global-components/page-header';
import TrainersPage from './section-components/trainers';
import Funfact from './section-components/fun-fact';
import Testimonial from './section-components/testimonial';
import Footer from './global-components/footer-v2';

const Trainers = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="Trainers" />
			<TrainersPage />
			<Funfact />
			<Testimonial />
			<Footer />
		</div>
	);
};

export default Trainers;
