import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { userSave } from '../../auth';
import { APIpost } from '../../apiCore';

const ForgotPassword = () => {
	const [values, setValues] = useState({
		email: '',
		error: '',
		success: false,
		loading: false,
		redirectToReferrer: false,
	});

	const handleChange = (name) => (e) => {
		setValues({ ...values, error: false, [name]: e.target.value });
	};

	const { email, error, success, loading, redirectToReferrer } = values;

	// console.log(values);

	const clickSubmit = async (event) => {
		event.preventDefault();
		setValues({ ...values, loading: true });
		await APIpost(`users/forgotPassword`, { email: email }).then((data) => {
			console.log(data);
			if (data.error) {
				return setValues({ ...values, error: data.error, success: false });
			}

			alert('Check Your Email');
		});

		// console.log(response);

		// console.log(`Making a request to: ${process.env.REACT_APP_API_PORT}`);
	};

	const showError = () => (
		<div
			className="alert alert-danger center"
			style={{ display: error ? '' : 'none' }}>
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{error}
		</div>
	);

	const showLoading = () =>
		loading && (
			<div style={{ display: success ? '' : 'none' }}>
				<div className="alert alert-info center">Signing in...</div>
				{/* <div className="center">
				<Link to="/bootcamps">Check out the bootcamps</Link>
			</div> */}
			</div>
		);

	const close = () => {
		setValues({ ...values, success: false, error: '' });
	};

	const redirectUser = () => {
		if (redirectToReferrer) {
			return <Redirect to="/" />;
		}
	};

	return (
		<div className="signin-page-area pd-top-120 pd-bottom-120">
			<div className="container">
				<div className="row justify-content-center">
					{showLoading()}
					{showError()}
					<div className="col-xl-6 col-lg-7">
						<form className="signin-inner">
							<div className="row">
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('email')}
											type="text"
											placeholder="Enter your email"
										/>
									</div>
								</div>
								<div className="col-12 mb-4">
									<button
										onClick={clickSubmit}
										className="btn btn-base w-100">
										Submit
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			{redirectUser()}
		</div>
	);
};

export default ForgotPassword;
