import React from 'react';
import Navbar from './global-components/navbar-v1';
import PageHeader from './global-components/page-header';
import Event from './section-components/event-page';
import Footer from './global-components/footer-v2';

const AboutPage = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="Events" />
			<Event />
			<Footer />
		</div>
	);
};

export default AboutPage;
