import { React, useState } from 'react';
import Navbar from './global-components/navbar-v1';
import PageHeader from './global-components/page-header';
import ForgotPassword from './section-components/forgot-password';
import Footer from './global-components/footer-v2';

const SingInPage = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="Forgot Password" />
			<ForgotPassword />
			<Footer />
		</div>
	);
};

export default SingInPage;
