import React from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner-v2';
import Intro from './section-components/intro-v2';
import About from './section-components/about';
import Price from './section-components/price';
import Client from './section-components/client';
import Event from './section-components/event';
import Footer from './global-components/footer-v2';

const Home_V2 = () => {
	return (
		<div>
			<Navbar />
			<Banner />
			<Intro />
			<About />
			<Price />
			<Client />
			<Event />
			<Footer />
		</div>
	);
};

export default Home_V2;
