import React from 'react';
import Navbar from './global-components/navbar-v1';
import PageHeader from './global-components/page-header';
import Upload from './section-components/upload';
import Footer from './global-components/footer-v2';

const uploadImage = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="Upload Files" />
			<Upload />
			<Footer />
		</div>
	);
};

export default uploadImage;
