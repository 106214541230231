import React from 'react';
import { Link } from 'react-router-dom';

const BootcampCard = ({ bootcamp }) => {
	let publicUrl = process.env.PUBLIC_URL + '/';
	// console.log('BootcampCard: ');
	// console.log(bootcamp);
	let imgsrc = publicUrl + 'assets/img/bootcamp/';
	let usrimgsrc = publicUrl + 'assets/img/author/';
	console.log(bootcamp);
	if (bootcamp.photo !== 'no-photo.jpg')
		imgsrc = `${process.env.REACT_APP_PUBLIC_PORT}images/`;
	if (bootcamp.trainer.photo !== 'user-no-photo.jpg')
		usrimgsrc = `${process.env.REACT_APP_PUBLIC_PORT}images/`;
	console.log(usrimgsrc);
	return (
		<div className="col-md-6">
			<Link to={`/bootcamp/${bootcamp._id}`}>
				<div className="single-course-inner">
					<div className="thumb">
						<img
							src={imgsrc + bootcamp.photo}
							alt="img"
						/>
					</div>
					<div className="details">
						<div className="details-inner">
							<div className="emt-user">
								<h4>
									<Link to={`/bootcamp/${bootcamp._id}`}>{bootcamp.name}</Link>
								</h4>
								<span className="u-thumb">
									<img
										src={usrimgsrc + bootcamp.trainer.photo}
										alt="img"
									/>
								</span>
								<span className="align-self-center">
									<Link to={`/trainer-details/${bootcamp.trainer._id}`}>
										{bootcamp.trainer.name} {bootcamp.trainer.surname}
									</Link>
								</span>
							</div>
							<div className="">
								<h6>{bootcamp.dateStart}</h6>
							</div>
						</div>

						<div className="emt-course-meta">
							<div className="row">
								<div className="col-6">
									<div className="media-body details">
										<p>
											<i className="fa fa-clock-o" /> {bootcamp.timeStart} To{' '}
											{bootcamp.timeEnd}
										</p>

										<p>
											<i className="fa fa-map-marker" /> {bootcamp.location}
										</p>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="price text-right">
										Price: <span>${bootcamp.cost}.00</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default BootcampCard;
