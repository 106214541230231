import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { APIget } from '../../apiCore';
import { Loading, Notify } from '../global-components/messaging';
import JobsCard from './jobsCard';

const EventPage = () => {
	let publicUrl = process.env.PUBLIC_URL + '/';

	const [jobs, setJobs] = useState([]);
	const [values, setValues] = useState({
		error: '',
		search: '',
		free: false,
		// page: 1,
		venue: '',
		success: true,
		loading: true,
		pagination: {
			next: {
				page: 2,
				limit: 9,
			},
			prev: {
				page: 1,
				limit: 9,
			},
		},
	});

	useEffect(() => {
		loadJobs();
	}, []);

	const { error, success, loading, free, search, online, venue } = values;
	console.log(`Destructering values: ${JSON.stringify(values)}`);
	console.log(`Pagination: ${JSON.stringify(values.pagination)}`);

	const APICall = async (page) => {
		console.log('Calling Jobs!');
		setValues({ ...values, loading: true });
		const qstr = formQueryStr(page);
		await APIget('jobs', qstr).then((data) => {
			console.log('Data recieved from jobs');
			console.log(data);
			// console.log(`Data Count: ${data.data.count}`);
			if (data.error)
				return setValues({
					...values,
					loading: false,
					error: data.error,
					success: false,
				});
			if (data.data.count !== 0) {
				setJobs(data.data.data);
				setValues({
					...values,

					loading: false,
					pagination: data.data.pagination,
				});
			} else {
				setValues({
					...values,

					loading: false,
					pagination: data.data.pagination,
				});
			}
		});
	};

	const loadJobs = async (page) => {
		await APICall(1);
	};

	const formQueryStr = (page) => {
		let queryStr = '';

		console.log(`Free: ${free}, Venue: ${venue}`);

		queryStr = queryStr.concat('&', `page=${page}`);

		if (search.length !== 0)
			queryStr = queryStr.concat('&', `search=${search}`);

		if (free) queryStr = queryStr.concat('&', 'cost=0');

		if (venue.length !== 0)
			queryStr = queryStr.concat('&', `location=${venue}`);

		console.log(`Query String: ${queryStr}`);

		return queryStr;
	};

	const handleChange = (name) => async (e) => {
		await setValues({ ...values, error: false, [name]: e.target.value });

		// await APICall();
	};

	const handleSearchButton = () => {
		setJobs('');
		setValues({ ...values, loading: true });
		APICall(1);
		setValues({ ...values, search: '' });
	};

	const handleLastPage = () => {
		console.log(values.pagination.totalPages);
		setValues({ ...values, page: values.pagination.totalPages });
		APICall(values.pagination.totalPages);
	};

	const handleNext = () => {
		if (values.pagination.next.page >= values.pagination.currentpage)
			APICall(values.pagination.next.page);
	};

	const handlePrev = () => {
		console.log('Previous page');
		if (values.pagination.prev.page <= values.pagination.currentpage) {
			console.log('Able to go to Previous page');
			APICall(values.pagination.prev.page);
		}
	};

	const isjobsEmpty = () => {
		let isEmpty = false;
		if (jobs.length === 0) {
			return (isEmpty = true);
		}

		return isEmpty;
	};

	const canCreate = () => {
		console.log('Checking Role');
		const lsUser = JSON.parse(localStorage.getItem('user'));
		let can = false;
		if (lsUser) {
			console.log(`User : `);
			console.log(lsUser.loggedInUser.role);
			if (lsUser.loggedInUser.role !== 'USER') can = true;
		}
		return can;
	};

	// const handleOnlineCheck = async (event) => {
	// 	if (event.target.checked) {
	// 		setValues({ ...values, online: true });
	// 	} else {
	// 		setValues({ ...values, online: false });
	// 	}
	// };

	return (
		<div className="event-area pd-top-120 pd-bottom-120 go-top">
			<div className="container">
				{values.loading ? (
					<Loading loadingText="Checking jobs" />
				) : (
					<div className="row">
						<div className="col-lg-8 order-lg-12">
							{canCreate() && (
								<Link to="/createjob">
									<button className="btn btn-base ">Create New Job</button>
								</Link>
							)}
							<div className="row go-top mt-5">
								<div className="center">
									{isjobsEmpty() && <Notify message={'No Jobs to show.'} />}
									{!success && <Notify message={values.error} />}
								</div>

								{!isjobsEmpty() &&
									jobs.map((job, i) => (
										<JobsCard
											key={i}
											job={job}
										/>
									))}
							</div>
							{!isjobsEmpty() && (
								<nav className="td-page-navigation center">
									<ul className="pagination">
										<li className="pagination-arrow">
											<button
												className="btn-pgn"
												onClick={handlePrev}>
												<i className="fa fa-angle-double-left" />
											</button>
										</li>
										<li>
											<button className="btn-pgn">
												{values.pagination.currentpage}
											</button>
										</li>
										<li>
											<button className="btn-pgn-uslss">/</button>
										</li>
										<li>
											<button
												className="btn-pgn"
												onClick={handleLastPage}>
												{values.pagination.totalPages}
											</button>
										</li>
										<li className="pagination-arrow">
											<button
												className="btn-pgn"
												onClick={handleNext}>
												<i className="fa fa-angle-double-right" />
											</button>
										</li>
									</ul>
								</nav>
							)}
						</div>

						<div className="col-lg-4 order-lg-1 col-12 mt-5">
							<div className="td-sidebar mt-5 mt-lg-0">
								<div className="widget widget_search_course">
									<h4 className="widget-title">Search</h4>
									<form className="search-form single-input-inner">
										<input
											type="text"
											placeholder="Search here"
											onChange={handleChange('search')}
										/>
									</form>
								</div>

								{/* <div className="widget widget_checkbox_list">
									<h4 className="widget-title">Bootcamp qualities</h4>
									<label className="single-checkbox">
										<input
											type="checkbox"
											name="Free"
											onChange={handleFreeCheck}
											checked={values.free}
										/>
										<span className="checkmark" />
										Free Bootcamps
									</label>
								</div> */}

								<div className="widget widget_checkbox_list">
									<h4 className="widget-title">Location</h4>

									<select
										onChange={handleChange('venue')}
										className=" col-12 mb-4 h-100 "
										name="field"
										id="field"
										value={venue}>
										<option value="">Any</option>
										<option value="Harare">Harare</option>
										<option value="Bulawayo">Bulawayo</option>
										<option value="Gweru">Gweru</option>
										<option value="Mutare">Mutare</option>
										<option value="Online">Online</option>
									</select>
								</div>

								{/* <div className="widget widget_tags mb-0">
									<h4 className="widget-title">Tags</h4>
									<div className="tagcloud go-top">
										<Link to="blog-details">Interviews</Link>
										<Link to="blog-details">Mining</Link>
										<Link to="blog-details">Human Resources</Link>
										<Link to="blog-details">Management</Link>
										<Link to="blog-details">Engineering</Link>
										<Link to="blog-details">Data Analyst</Link>
									</div>
								</div> */}
								<div className="widget widget_search_course">
									<form className="search-form single-input-inner">
										<button
											onClick={handleSearchButton}
											className="btn btn-base w-100 mt-3"
											type="submit">
											<i className="fa fa-search" /> SEARCH
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default EventPage;
