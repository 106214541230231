import React, { useState } from 'react';
import { APIpost } from '../../apiCore';
import { Link, Redirect } from 'react-router-dom';
import parse from 'html-react-parser';

const CreateBootcamp = () => {
	// console.log(`API Location: ${process.env.REACT_APP_API_PORT}`);
	const [values, setValues] = useState({
		name: '',
		description: '',
		location: '',
		trainer: '',
		dateStart: '',
		dateEnd: '',
		timeStart: '',
		timeEnd: '',
		careers: '',
		cost: 0,
		error: '',
		successMessage: '',
		success: false,
		loading: false,
		redirectToReferrer: false,
	});

	const handleChange = (name) => (e) => {
		setValues({ ...values, error: false, [name]: e.target.value });
	};

	let {
		name,
		description,
		location,
		trainer,
		dateStart,
		dateEnd,
		timeStart,
		timeEnd,
		careers,
		cost,
		error,
		successMessage,
		success,
		loading,
		redirectToReferrer,
	} = values;

	// console.log(values);

	const clickSubmit = async (event) => {
		event.preventDefault();
		console.log(careers);

		careers = careers.split(',');
		console.log(careers);
		console.log('Click SUbmit');

		await APIpost('bootcamps', {
			name,
			description,
			location,
			trainer,
			dateStart,
			dateEnd,
			timeStart,
			timeEnd,
			careers,
			cost,
		}).then((response) => {
			console.log(response);
			if (response.error) {
				return setValues({ ...values, error: response.error, success: false });
			}

			setValues({
				name: '',
				description: '',
				location: '',
				trainer: '',
				dateStart: '',
				dateEnd: '',
				timeStart: '',
				timeEnd: '',
				careers: '',
				cost: 0,
				error: '',
				successMessage: response.data.message,
				success: true,
				loading: false,
				redirectToReferrer: false,
			});
		});
	};

	const showError = () => (
		<div
			className="alert alert-danger center"
			style={{ display: error ? '' : 'none' }}>
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{error}
		</div>
	);

	const close = () => {
		setValues({ ...values, success: false, error: '' });
	};

	const showSuccess = () => (
		<div
			style={{ display: success ? '' : 'none' }}
			className="alert alert-info center">
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{successMessage}
		</div>
	);

	const showLoading = () =>
		loading && (
			<div style={{ display: success ? '' : 'none' }}>
				<div className="alert alert-info center">Creating New Job...</div>
			</div>
		);

	const redirectUser = () => {
		if (redirectToReferrer) {
			return <Redirect to="/bootcamps" />;
		}
	};

	console.log(values);

	return (
		<div className="signup-page-area pd-top-120 pd-bottom-120">
			<div className="container">
				<div className="row justify-content-center">
					{showLoading()}
					{showSuccess()}
					{showError()}
					{redirectUser()}
					<div className="col-xl-6 col-lg-7">
						<form className="signin-inner">
							<div className="row">
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('name')}
											type="text"
											placeholder="Bootcamp Name"
											value={name}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<textarea
											onChange={handleChange('description')}
											type="text"
											placeholder="Bootcamp description"
											value={description}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('trainer')}
											type="text"
											placeholder="Trainer"
											value={trainer}
										/>
									</div>
								</div>

								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('cost')}
											type="number"
											min={0}
											placeholder="0"
											value={cost}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<textarea
											onChange={handleChange('careers')}
											type="text"
											placeholder="Careers. separate using comma (,)"
											value={careers}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('location')}
											type="text"
											placeholder="Location of Bootcamp"
											value={location}
										/>
									</div>
								</div>

								<p>Starting</p>

								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('dateStart')}
											type="date"
											id="dateStart"
											name="dateStart"
											placeholder="Starting Date"
											value={dateStart}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('timeStart')}
											type="time"
											id="timeStart"
											name="timeStart"
											value={timeStart}
										/>
									</div>
								</div>
								<p>To</p>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('dateEnd')}
											type="date"
											id="dateEnd"
											name="dateEnd"
											value={dateEnd}
										/>
									</div>
								</div>

								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('timeEnd')}
											type="time"
											id="timeEnd"
											name="timeEnd"
											value={timeEnd}
										/>
									</div>
								</div>
								<div className="col-12 mb-4">
									<button
										onClick={clickSubmit}
										className="btn btn-base w-100">
										Create Bootcamp
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateBootcamp;
