import React, { useState } from 'react';
import { signup, userSave } from '../../auth';
import { Link, Redirect } from 'react-router-dom';
import parse from 'html-react-parser';

const SignUp = () => {
	// console.log(`API Location: ${process.env.REACT_APP_API_PORT}`);
	const [values, setValues] = useState({
		name: '',
		surname: '',
		email: '',
		phone: '',
		password: '',
		passwordConfirm: '',
		field: 'Science',
		gender: 'Male',
		dob: '',
		showPassword: true,
		error: '',
		success: false,
		loading: false,
		redirectToReferrer: false,
	});

	const handleChange = (name) => (e) => {
		setValues({ ...values, error: false, [name]: e.target.value });
	};

	const {
		name,
		surname,
		email,
		password,
		passwordConfirm,
		field,
		gender,
		dob,
		phone,
		address,
		location,
		error,
		success,
		loading,
		redirectToReferrer,
	} = values;

	// console.log(values);

	const clickSubmit = async (event) => {
		event.preventDefault();
		setValues({ ...values, loading: true });
		if (password != passwordConfirm) {
			return setValues({
				...values,
				error: 'Passwords do not match',
			});
		}
		await signup(
			name,
			surname,
			email,
			password,
			field,
			dob,
			gender,
			phone,
			address,
			location
		).then((data) => {
			console.log(data);
			if (data.error) {
				return setValues({ ...values, error: data.error, success: false });
			}
			userSave(data.data, () => {
				setValues({
					...values,
					name: '',
					surname: '',
					email: '',
					phone: '',
					password: '',
					passwordConfirm: '',
					field: 'Science',
					gender: 'Male',
					location: '',
					address: '',
					dob: '',
					error: '',
					showPassword: false,
					success: true,
					redirectToReferrer: true,
				});
			});
		});

		// console.log(response);

		// console.log(`Making a request to: ${process.env.REACT_APP_API_PORT}`);
	};

	const showError = () => (
		<div
			className="alert alert-danger center"
			style={{ display: error ? '' : 'none' }}>
			{error}
		</div>
	);

	const showSuccess = () => (
		<div style={{ display: success ? '' : 'none' }}>
			<div className="alert alert-info center">
				New account created and logged in.
			</div>
			{/* <div className="center">
				<Link to="/bootcamps">Check out the bootcamps</Link>
			</div> */}
		</div>
	);

	const showLoading = () =>
		loading && (
			<div style={{ display: success ? '' : 'none' }}>
				<div className="alert alert-info center">Creating New User...</div>
			</div>
		);

	const redirectUser = () => {
		if (redirectToReferrer) {
			return <Redirect to="/bootcamps" />;
		}
	};

	const handleShowPassword = async (event) => {
		if (event.target.checked) {
			setValues({ ...values, showPassword: true });
		} else {
			setValues({ ...values, showPassword: false });
		}
	};

	return (
		<div className="signup-page-area pd-top-120 pd-bottom-120">
			{showLoading()}
			{showSuccess()}
			{showError()}
			{redirectUser()}
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-6 col-lg-7">
						<form className="signin-inner">
							<div className="row">
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('name')}
											type="text"
											placeholder="First Name"
											value={name}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('surname')}
											type="text"
											placeholder="Last Name"
											value={surname}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('email')}
											type="text"
											placeholder="Email"
											value={email}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('password')}
											type={values.showPassword ? 'text' : 'password'}
											placeholder="Password"
											value={password}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('passwordConfirm')}
											type={values.showPassword ? 'text' : 'password'}
											placeholder="Confirm Password"
											value={passwordConfirm}
										/>
									</div>
								</div>
								<div className="col-12">
									<label className="single-checkbox">
										<input
											type="checkbox"
											name="Free"
											onChange={handleShowPassword}
											checked={values.free}
										/>
										<span className="checkmark" />
										Show password
									</label>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('phone')}
											type="tel"
											placeholder="Phone Number (777 777 777)"
											value={phone}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('address')}
											type="text"
											placeholder="Address"
											value={address}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('location')}
											type="text"
											placeholder="City"
											value={location}
										/>
									</div>
								</div>
								Field
								<div className="col-12  single-input-inner style-bg-border">
									<select
										onChange={handleChange('field')}
										name="field"
										id="field">
										<option value="science">Science</option>
										<option value="technology">Technology</option>
										<option value="engineering">Engineering</option>
										<option value="mathematics">Mathematics</option>
									</select>
								</div>
								<div className="col-12  single-input-inner style-bg-border">
									<select
										onChange={handleChange('gender')}
										name="gender"
										id="gender">
										<option value="Male">Male</option>
										<option value="Female">Female</option>
									</select>
								</div>
								Date Of Birth
								<div className="col-12">
									<div className="single-input-inner style-bg-border">
										<input
											onChange={handleChange('dob')}
											type="date"
											id="birthday"
											name="birthday"
											value={dob}
										/>
									</div>
								</div>
								<div className="col-12 mb-4">
									<button
										onClick={clickSubmit}
										className="btn btn-base w-100">
										Create Account
									</button>
								</div>
								<div className="col-12">
									<span>Already have an account?</span>
									<Link to="/sign-in">
										<strong> Signin</strong>
									</Link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
