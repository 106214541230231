import { Link } from 'react-router-dom';

const TrainersCard = ({ trainer }) => {
	let publicUrl = process.env.PUBLIC_URL + '/';
	let usrimgsrc = `${process.env.REACT_APP_PUBLIC_PORT}images/`;
	console.log(trainer);
	return (
		<div className="col-lg-4 col-md-6">
			<div className="single-team-inner">
				<div className="thumb">
					<img
						src={usrimgsrc + trainer.photo}
						alt="img"
					/>
					{/* <div className="social-wrap">
						<div className="social-wrap-inner">
							<a
								className="social-share"
								href="#">
								<i className="fa fa-share-alt" />
							</a>
							<ul>
								<li>
									<a href="#">
										<i className="fa fa-facebook" />
									</a>
								</li>
								<li>
									<a href="#">
										<i className="fa fa-twitter" />
									</a>
								</li>
								<li>
									<a href="#">
										<i className="fa fa-pinterest" />
									</a>
								</li>
								<li>
									<a href="#">
										<i className="fa fa-linkedin" />
									</a>
								</li>
							</ul>
						</div>
					</div> */}
				</div>
				<div className="details">
					<h4>
						<Link to={`/trainer-details/${trainer._id}`}>
							{trainer.name} {trainer.surname}
						</Link>
					</h4>
					<span>{trainer.profession}</span>
				</div>
			</div>
		</div>
	);
};

export default TrainersCard;
