import React from 'react';
import Navbar from './global-components/navbar-v1';
import PageHeader from './global-components/page-header';
import Modify from './section-components/modify-acc';
import Footer from './global-components/footer-v2';

const ModifyPage = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="Modify Account" />
			<Modify />
			<Footer />
		</div>
	);
};

export default ModifyPage;
