import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { APIget } from '../../apiCore';
import { Loading, Notify } from '../global-components/messaging';

const JobDetails = () => {
	let publicUrl = process.env.PUBLIC_URL + '/';
	let imgsrc = `${process.env.REACT_APP_PUBLIC_PORT}images/`;

	const location = useLocation();
	const locationString = String(location.pathname);
	const slug = locationString.replace('/job/', '');

	let error = '';

	const [values, setValues] = useState({
		loading: false,
		error: '',
		success: true,
		job: {
			fields: [],
		},
	});

	const loadJob = async () => {
		setValues({ ...values, loading: true });
		await APIget('jobs/', `slug=${slug}`).then((response) => {
			console.log(response.data.data[0]);
			if (response.error)
				return setValues({
					...values,
					loading: false,
					success: false,
					error: response.error,
				});

			setValues({
				...values,
				loading: false,
				success: response.data.success,
				job: response.data.data[0],
			});
		});
	};

	const isJob = () => {
		let job = false;
		if (values.job !== undefined) job = true;
		return job;
	};

	const canUpload = () => {
		console.log('Checking Role');
		const lsUser = JSON.parse(localStorage.getItem('user'));
		let can = false;
		if (lsUser) {
			console.log(`User : `);
			console.log(lsUser.loggedInUser.role);
			if (lsUser.loggedInUser.role === 'ADM' || 'TRN') can = true;
		}
		return can;
	};

	useEffect(() => {
		loadJob();
	}, []);

	console.log(values);
	console.log(values.job.fields);

	return (
		<div className="course-single-area pd-top-120 pd-bottom-110">
			<div className="container">
				{values.loading ? (
					<Loading loadingText="Fetching Job Details" />
				) : isJob() ? (
					<div className="row">
						<div className="col-lg-8 order-lg-12">
							<div className="event-detaila-inner">
								<h1></h1>
								<div className="thumb mb-4">
									<img
										src={imgsrc + values.job.photo}
										alt="img"
									/>
								</div>
								{canUpload() && (
									<Link to={`/upload/jobs/${values.job._id}`}>
										<button className="btn btn-base w-100 ">
											Upload New Bootcamp Image
										</button>
									</Link>
								)}
								<ul className="event-meta">
									{/* <li>
									<i className="fa fa-clock-o" /> 12:00 AM To 11:59 PM
								</li>
								<li>
									<i className="fa fa-map-marker" /> Melbourne, Australia
								</li> */}
								</ul>
								<p>{values.job.description}</p>
								<div className="row pt-4 pb-4">
									<div className="col-lg-7 align-self-center">
										<h6>Application details</h6>
										{/* <p className="mb-0">{values.job.applicationDetails}</p> */}
									</div>
								</div>
								<p>{values.job.applicationDetails}</p>
							</div>
						</div>
						<div className="col-lg-4 order-lg-1">
							<div className="td-sidebar">
								<div className="widget widget_event">
									<h4 className="widget-title text-white">Job Info :</h4>
									<ul>
										{/* <li>
											<i className="fa fa-calendar" />
											Added: {date}
										</li> */}
										<li>
											<i className="fa fa-clock-o" />
											Closing Date: {values.job.closingDate}
										</li>

										<li>
											<i className="fa fa-map-marker" />
											Location: {values.job.location}
										</li>
										<li>
											<i className="fa fa-calendar" />
											{values.job.fields.map((field) => (
												<span>{field + ', '}</span>
											))}
										</li>
									</ul>
								</div>
								{/* <div className="widget widget_catagory">
								<h4 className="widget-title">Location</h4>
								<div className="widget-g-map">
									<iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d29208.601361499546!2d90.3598076!3d23.7803374!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1589109092857!5m2!1sen!2sbd" />
								</div>
							</div> */}
								{/* <div className="widget widget-contact">
								<h4 className="widget-title">Get a Quote</h4>
								<div className="single-input-inner style-right-icon">
									<input
										type="text"
										placeholder="Full name"
									/>
									<img
										src={publicUrl + 'assets/img/icon/25.png'}
										alt="img"
									/>
								</div>
								<div className="single-input-inner style-right-icon">
									<input
										type="text"
										placeholder="Email Address"
									/>
									<img
										src={publicUrl + 'assets/img/icon/26.png'}
										alt="img"
									/>
								</div>
								<div className="single-input-inner style-right-icon">
									<textarea
										placeholder="Your Message"
										defaultValue={''}
									/>
									<img
										src={publicUrl + 'assets/img/icon/27.png'}
										alt="img"
									/>
								</div>
								<a
									className="btn btn-base"
									href="#">
									Send Message
								</a>
							</div> */}
							</div>
						</div>
					</div>
				) : (
					<Notify message={'No job to show'} />
				)}
			</div>
		</div>
	);
};

export default JobDetails;
