import axios from 'axios';
import { Redirect } from 'react-router-dom';

export const signup = async (
	name,
	surname,
	email,
	password,
	field,
	dob,
	gender,
	phone,
	address,
	location
) => {
	return axios
		.post(`${process.env.REACT_APP_API_PORT}/users/`, {
			name,
			surname,
			field,
			gender,
			dob,
			email,
			phone,
			address,
			location,
			password,
		})
		.then((response) => {
			console.log(response);
			return response;
		})
		.catch((error) => {
			console.log(`This is the error: ${error}`);
			if (error.message === 'Network Error') {
				console.log('Error is Network');
				let error = 'Network Error';
				return error;
			}
			return error.response.data;
		});
};

export const signin = async (email, password) => {
	return axios
		.post(`${process.env.REACT_APP_API_PORT}/users/login`, {
			email,
			password,
		})
		.then((response) => {
			console.log(response);
			return response;
		})
		.catch((error) => {
			if (error.message === 'Network Error') {
				console.log('Error is Network');
				let error = 'Network Error';
				return error;
			}

			// console.log(`This is response.data: ${error.response.data}`);
			return error.response.data;
		});
};

export const passwordReset = async (password, resetToken) => {
	return axios
		.put(
			`${process.env.REACT_APP_API_PORT}/users/resetpassword/${resetToken}`,
			{
				password,
			}
		)
		.then((response) => {
			console.log(response);
			return response;
		})
		.catch((error) => {
			if (error.message === 'Network Error') {
				console.log('Error is Network');
				let error = 'Network Error';
				return error;
			}

			// console.log(`This is response.data: ${error.response.data}`);
			return error.response.data;
		});
};

export const userSave = async (data, next) => {
	console.log(data);
	if (typeof window !== 'undefined') {
		console.log(`Saving User: ${JSON.stringify(data)}`);
		await localStorage.setItem('user', JSON.stringify(data));
		console.log(localStorage.getItem('user'));
		next();
	}
};

export const signOut = () => {
	if (typeof window !== 'undefined') {
		localStorage.removeItem('user');
		window.location.replace('/');
	}
};

export const isAuthenticated = () => {
	if (typeof window === 'undefined') return false;

	if (localStorage.getItem('user'))
		return JSON.parse(localStorage.getItem('user'));
	else return false;
};
