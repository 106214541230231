import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Notify, Loading } from '../global-components/messaging';
import { APIget } from '../../apiCore';

const TrainerDetails = () => {
	let publicUrl = process.env.PUBLIC_URL + '/';
	let usrimgsrc = `${process.env.REACT_APP_PUBLIC_PORT}images/`;

	const location = useLocation();
	const locationString = String(location.pathname);
	const trainerId = locationString.replace('/trainer-details/', '');

	let error = '';

	const [values, setValues] = useState({
		loading: false,
		success: true,
		error: '',
		trainer: {},
	});

	const loadTrainer = async () => {
		setValues({ ...values, loading: true });
		await APIget(`Users/${trainerId}`, '').then((response) => {
			console.log('Trainer load result:');
			console.log(response);
			if (response.error) {
				return setValues({
					...values,
					loading: false,
					success: false,
					error: response.error,
				});
			}
			if (response.data.success) {
				setValues({ ...values, loading: false, trainer: response.data.data });
			}

			error = values.error;

			if (values.error.includes('')) {
				return (error = 'Could find not the Trainer you requested');
			}
		});
	};

	useEffect(() => {
		loadTrainer();
	}, []);

	const isTrainer = () => {
		let trainer = true;
		if (values.trainer.id === undefined) trainer = false;
		return trainer;
	};

	const isFacebook = () => {
		let facebook = false;
		if (values.trainer.socialMedia !== undefined)
			if (values.trainer.socialMedia.facebook !== undefined) facebook = true;
		return facebook;
	};

	const isTwitter = () => {
		let twitter = false;
		if (values.trainer.socialMedia !== undefined)
			if (values.trainer.socialMedia.twitter !== undefined) twitter = true;
		return twitter;
	};

	const isLinkedIn = () => {
		let linkedIn = false;
		if (values.trainer.socialMedia !== undefined)
			if (values.trainer.socialMedia.linkedIn !== undefined) linkedIn = true;
		return linkedIn;
	};

	const isPinterest = () => {
		let pinterest = false;
		if (values.trainer.socialMedia !== undefined)
			if (values.trainer.socialMedia.pinterest !== undefined) pinterest = true;
		return pinterest;
	};

	const isInstagram = () => {
		let instagram = false;
		if (values.trainer.socialMedia !== undefined)
			if (values.trainer.socialMedia.instagram !== undefined) instagram = true;
		return instagram;
	};
	const showSocials = () => {
		let value = false;

		if (
			isFacebook() ||
			isInstagram() ||
			isLinkedIn() ||
			isPinterest() ||
			isTwitter()
		)
			value = true;
		console.log(`facebook: ${isFacebook()}`);
		console.log(`Instagram: ${isInstagram()}`);
		console.log(`LinkedIn: ${isLinkedIn()}`);
		console.log(`Pinterest: ${isPinterest()}`);
		console.log(`Twitter: ${isTwitter()}`);
		console.log(value);
		return value;
	};

	console.log(values);
	console.log(`Socials: ${showSocials()}`);

	return (
		<div className="main-blog-area pd-top-120 pd-bottom-90">
			<div className="container">
				{values.loading ? (
					<Loading loadingText="Fetching Trainer Details" />
				) : isTrainer() ? (
					<div>
						<div className="team-details-page">
							<div className="row">
								<div className="col-lg-5">
									<div className="thumb">
										<img
											src={usrimgsrc + values.trainer.photo}
											alt="img"
										/>
									</div>
								</div>
								<div className="col-lg-7 align-self-center mt-5 mt-lg-0">
									<div className="details">
										<h3>
											{values.trainer.name} {values.trainer.surname}
										</h3>
										<span className="designation">{values.trainer.field}</span>
										{showSocials() && (
											<ul className="social-media style-base pt-2 mb-4">
												<li>
													{isFacebook() && (
														<a href={values.trainer.socialMedia.facebook}>
															<i
																className="fa fa-facebook"
																aria-hidden="true"
															/>
														</a>
													)}
												</li>
												<li>
													{isTwitter() && (
														<a href={values.trainer.socialMedia.twitter}>
															<i
																className="fa fa-twitter"
																aria-hidden="true"
															/>
														</a>
													)}
												</li>
												<li>
													{isInstagram() && (
														<a href={values.trainer.socialMedia.instagram}>
															<i
																className="fa fa-instagram"
																aria-hidden="true"
															/>
														</a>
													)}
												</li>
												<li>
													{isLinkedIn() && (
														<a href={values.trainer.socialMedia.linkedIn}>
															<i
																className="fa fa-linkedin"
																aria-hidden="true"
															/>
														</a>
													)}
												</li>
												<li>
													{isPinterest() && (
														<a href={values.trainer.socialMedia.pinterest}>
															<i
																className="fa fa-pinterest"
																aria-hidden="true"
															/>
														</a>
													)}
												</li>
											</ul>
										)}

										<span>{values.trainer.socialMedia.website}</span>
										<span>{values.trainer.email}</span>
										<span>{values.trainer.phone}</span>

										{/* <p className="mt-4">
											Waltz, bad nymph, for quick jigs vex! Fox nymphs grab
											quick-jived waltz. Brick quiz whangs jumpy veldt quiz
											graced by fox whelps. Bawds jog, flick quartz, vex nymphs.
											Waltz, bad nymph, for quick jigs vex! Fox{' '}
										</p> */}
									</div>
								</div>
							</div>
							<div className="details-inner mt-4 pt-xl-3">
								<h4>Occupation</h4>
								{values.trainer.occupation.map((occ) => (
									<div>
										<h5 className="mt-4">
											{occ.role} {occ.company}
										</h5>
										<span>
											{occ.dateStart} - {occ.dateEnd}
										</span>
										<p className="mt-3">{occ.description}</p>
									</div>
								))}
							</div>
						</div>
						{/* <div className="course-area pd-top-90">
							<h4 className="mb-4">Instructor Course</h4>
							<div className="row">
								<div className="col-lg-4 col-md-6">
									<div className="single-course-inner">
										<div className="thumb">
											<img
												src={publicUrl + 'assets/img/course/1.png'}
												alt="img"
											/>
										</div>
										<div className="details">
											<div className="details-inner">
												<div className="emt-user">
													<span className="u-thumb">
														<img
															src={publicUrl + 'assets/img/author/1.png'}
															alt="img"
														/>
													</span>
													<span className="align-self-center">Nancy Reyes</span>
												</div>
												<h6 className="go-top">
													<Link to="/course-details">
														Fox nymphs grab quick-jived waltz. Brick quiz whangs
													</Link>
												</h6>
											</div>
											<div className="emt-course-meta">
												<div className="row">
													<div className="col-6">
														<div className="rating">
															<i className="fa fa-star" /> 4.3
															<span>(23)</span>
														</div>
													</div>
													<div className="col-6">
														<div className="price text-right">
															Price: <span>$54.00</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6">
									<div className="single-course-inner">
										<div className="thumb">
											<img
												src={publicUrl + 'assets/img/course/2.png'}
												alt="img"
											/>
										</div>
										<div className="details">
											<div className="details-inner">
												<div className="emt-user">
													<span className="u-thumb">
														<img
															src={publicUrl + 'assets/img/author/2.png'}
															alt="img"
														/>
													</span>
													<span className="align-self-center">Joe Powell</span>
												</div>
												<h6 className="go-top">
													<Link to="/course-details">
														Aenean sed nibh a magna posuere tempo faucib
													</Link>
												</h6>
											</div>
											<div className="emt-course-meta">
												<div className="row">
													<div className="col-6">
														<div className="rating">
															<i className="fa fa-star" /> 4.3
															<span>(23)</span>
														</div>
													</div>
													<div className="col-6">
														<div className="price text-right">
															Price: <span>$54.00</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6">
									<div className="single-course-inner">
										<div className="thumb">
											<img
												src={publicUrl + 'assets/img/course/3.png'}
												alt="img"
											/>
										</div>
										<div className="details">
											<div className="details-inner">
												<div className="emt-user">
													<span className="u-thumb">
														<img
															src={publicUrl + 'assets/img/author/3.png'}
															alt="img"
														/>
													</span>
													<span className="align-self-center">
														Timothy Willis
													</span>
												</div>
												<h6 className="go-top">
													<Link to="/course-details">
														Praesent eu dolor eu orci vehicula euismod
													</Link>
												</h6>
											</div>
											<div className="emt-course-meta">
												<div className="row">
													<div className="col-6">
														<div className="rating">
															<i className="fa fa-star" /> 4.9
															<span>(73)</span>
														</div>
													</div>
													<div className="col-lg-6">
														<div className="price text-right">
															Price: <span>$74.00</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				) : (
					<Notify message={'No trainer to show.'} />
				)}
			</div>
		</div>
	);
};

export default TrainerDetails;
