import React from 'react';
import { Link } from 'react-router-dom';

const JobsCard = ({ job }) => {
	let publicUrl = process.env.PUBLIC_URL + '/';

	// let imgsrc = publicUrl + 'assets/img/bootcamp/';
	// console.log('Jobs: ');
	// console.log(job);
	// if (job.photo !== 'job-no-photo.jpg')
	let imgsrc = `${process.env.REACT_APP_PUBLIC_PORT}images/`;

	return (
		<div className="col-md-6">
			<Link to={`/bootcamp/${job.slug}`}>
				<div className="single-course-inner">
					<div className="thumb">
						<img
							src={imgsrc + job.photo}
							alt="img"
						/>
					</div>
					<div className="details">
						<div className="details-inner">
							<div className="emt-user">
								<h4>
									<Link to={`/job/${job.slug}`}>{job.name}</Link>
								</h4>
								<span className="align-self-center">
									<p>
										<i className="fa fa-map-marker" /> {job.location}
									</p>
								</span>
							</div>
							<div className="">
								<h6>Closing Date: {job.closingDate}</h6>
							</div>
						</div>

						{/* <div className="emt-course-meta">
							<div className="row">
								<div className="col-6">
									<div className="media-body details">
										<p>
											<i className="fa fa-clock-o" /> {bootcamp.timeStart} To{' '}
											{bootcamp.timeEnd}
										</p>

										<p>
											<i className="fa fa-map-marker" /> {bootcamp.location}
										</p>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="price text-right">
										Price: <span>${bootcamp.cost}.00</span>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</Link>
		</div>
	);
};

export default JobsCard;
