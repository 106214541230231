import React from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { isAuthenticated, signOut } from '../../auth';
import { isMobile } from 'react-device-detect';

const NavbarV4 = () => {
	let publicUrl = process.env.PUBLIC_URL + '/';
	const lsUser = JSON.parse(localStorage.getItem('user'));
	const isAuthorized = () => {
		let can = false;
		if (lsUser) {
			if (lsUser.loggedInUser.role !== 'USER') can = true;
		}
		return can;
	};

	const location = useLocation();

	let hideAuth = false;

	if (location.pathname === '/sign-in') hideAuth = true;
	if (location.pathname === '/sign-up') hideAuth = true;

	const signOutUser = () => {
		signOut();
	};

	const mobileMenuOpen = async () => {
		// const mobileMenu = document.querySelector('edumint_main_menu');

		// if (mobileMenu.classList.contains('sopen')) return true;
		if (await document.querySelector('.sopen')) return true;
		return false;
	};
	console.log(`is Mobile: ${isMobile}`);

	const checkMobileMenu = () => {
		console.log(`Mobile Menu is open: ${mobileMenuOpen()}`);
		console.log(`is Mobile: ${isMobile}`);
	};

	return (
		<div className="navbar-area">
			<div className="navbar-top">
				<div className="container">
					<div className="row">
						<div className="col-md-8 text-md-left text-center">
							<ul>
								<li>
									<p>
										<i className="fa fa-map-marker" /> 4 Link Road Mt Pleasant
										Harare
									</p>
								</li>
								<li>
									<p>
										<i className="fa fa-envelope-o" />{' '}
										info@stemskillsfactory.org
									</p>
								</li>
							</ul>
						</div>
						<div className="col-md-4">
							<ul className="topbar-right text-md-right text-center">
								<li className="social-area">
									<a href="https://www.facebook.com/stemskillsfactory">
										<i
											className="fa fa-facebook"
											aria-hidden="true"
										/>
									</a>
									{/* <a href="https://www.facebook.com/stemskillsfactory">
										<i
											className="fa fa-twitter"
											aria-hidden="true"
										/>
									</a> */}
									<a href="https://www.instagram.com/stemskillsfactory/">
										<i
											className="fa fa-instagram"
											aria-hidden="true"
										/>
									</a>
									<a href="https://www.linkedin.com/company/stem-skills-factory">
										<i
											className="fa fa-linkedin"
											aria-hidden="true"
										/>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<nav className="navbar bg-white navbar-area-1 navbar-area navbar-expand-lg go-top">
				<div className="container nav-container">
					<div className="responsive-mobile-menu">
						<button
							onClick={checkMobileMenu}
							className="menu toggle-btn d-block d-lg-none"
							data-target="#edumint_main_menu"
							aria-expanded="false"
							aria-label="Toggle navigation">
							<span className="icon-left" />
							<span className="icon-right" />
						</button>
					</div>
					<div className="logo">
						<Link to="/">
							<img
								src={publicUrl + 'assets/img/logo.png'}
								alt="img"
							/>
						</Link>
					</div>
					<div className="nav-right-part nav-right-part-mobile">
						<Link
							className="btn btn-base"
							to="/sign-in">
							Sign in
						</Link>
					</div>
					<div
						className="collapse navbar-collapse"
						id="edumint_main_menu">
						{isAuthorized() ? (
							<ul className="navbar-nav menu-open">
								<li className="current-menu-item">
									<Link to="/">Home</Link>
								</li>
								<li>
									<Link to="/bootcamps">Events</Link>
								</li>
								<li>
									<Link to="/jobs">Jobs</Link>
								</li>
								<li>
									<Link to="/users">Users</Link>
								</li>
								<li>
									<Link to="/createbootcamp">Create Event</Link>
								</li>
								<li>
									<Link to="/createjob">Create Job</Link>
								</li>
							</ul>
						) : (
							<ul className="navbar-nav menu-open">
								<li className="current-menu-item">
									<Link to="/">Home</Link>
								</li>
								<li>
									<Link to="/bootcamps">Events</Link>
								</li>
								<li>
									<Link to="/jobs">Jobs</Link>
								</li>
								<li>
									<Link to="/trainers">Trainers</Link>
								</li>
								<li>
									<Link to="/about">About Us</Link>
								</li>
								<li>
									<Link to="/contact">Contact Us</Link>
								</li>

								{isMobile && mobileMenuOpen() && isAuthenticated() && (
									<li>
										<Link to={`/user-details/${lsUser.loggedInUser._id}`}>
											My Account
										</Link>
									</li>
								)}
								{isMobile && mobileMenuOpen() && isAuthenticated() && (
									<li>
										<Link
											onClick={signOutUser}
											to="/">
											Sign Out{' '}
										</Link>
									</li>
								)}
								{isMobile && mobileMenuOpen() && !isAuthenticated() && (
									<li style={{ color: 'green' }}>
										<Link to="/sign-in">Sign In </Link>
									</li>
								)}
								{isMobile && mobileMenuOpen() && !isAuthenticated() && (
									<li>
										<Link to="/sign-up">Sign Up </Link>
									</li>
								)}
							</ul>
						)}
					</div>
					{!isAuthenticated() && !hideAuth && (
						<div className="nav-right-part nav-right-part-desktop style-black">
							<Link
								className="btn"
								to="/sign-in">
								Sign In{' '}
							</Link>

							<Link
								className="btn btn-base"
								to="/sign-up">
								Sign Up
							</Link>
						</div>
					)}
					{isAuthenticated() && !hideAuth && (
						<div className="nav-right-part nav-right-part-desktop ">
							<Link
								className="userAcc"
								to={`/user-details/${lsUser.loggedInUser._id}`}>
								<i className="fa fa-user-circle-o" />
							</Link>
							<a
								className="btn btn-base style-black"
								onClick={signOutUser}>
								Sign Out
							</a>
						</div>
					)}
				</div>
			</nav>
		</div>
	);
};

export default NavbarV4;
