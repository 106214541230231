import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import BootcampDetails from './section-components/bootcamp';
import Footer from './global-components/footer-v2';

const AboutPage = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="Event Details" />
			<BootcampDetails />
			<Footer />
		</div>
	);
};

export default AboutPage;
