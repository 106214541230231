import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV2 extends Component {
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/';

		let SectionClass = this.props.sectionclass ? this.props.sectionclass : '';

		return (
			<div className={'about-area pd-top-120 go-top ' + SectionClass}>
				<div className="container">
					<div className="about-area-inner">
						<div className="row">
							<div className="col-lg-6 col-md-10">
								<div
									className="about-thumb-wrap after-shape"
									style={{
										backgroundImage:
											'url("' +
											publicUrl +
											'assets/img/about/AboutBanner.png")',
									}}></div>
							</div>
							<div className="col-lg-6">
								<div className="about-inner-wrap">
									<div className="section-title mb-0">
										<h6 className="sub-title right-line">ABOUT US</h6>
										<h2 className="title">
											We groom those in search for good jobs.
										</h2>
										<p className="content">
											We expose graduates and experts from different fields
											(especially the STEM field) for job opportunities through
											a number of exercises, events and courses.
										</p>
										<div className="row">
											<div className="col-sm-6">
												<ul className="single-list-wrap">
													<li className="single-list-inner style-check-box">
														<i className="fa fa-check" /> Grooming
													</li>
													<li className="single-list-inner style-check-box">
														<i className="fa fa-check" /> Etiqutte
													</li>
													<li className="single-list-inner style-check-box">
														<i className="fa fa-check" /> Training
													</li>
												</ul>
											</div>
											<div className="col-sm-6">
												<ul className="single-list-wrap">
													<li className="single-list-inner style-check-box">
														<i className="fa fa-check" /> Job Shadowing
													</li>
													<li className="single-list-inner style-check-box">
														<i className="fa fa-check" /> On-Site Tours
													</li>
													<li className="single-list-inner style-check-box">
														<i className="fa fa-check" /> Talks with HR agents
													</li>
												</ul>
											</div>
										</div>
										<Link
											className="btn btn-border-black "
											to="/about">
											Read More
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AboutV2;
