import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Notify, Loading } from '../global-components/messaging';
import { APIget, APIput } from '../../apiCore';

const UserDetails = () => {
	const usrimgsrc = `${process.env.REACT_APP_PUBLIC_PORT}images/`;
	const userdoc = `${process.env.REACT_APP_PUBLIC_PORT}documents/`;

	const location = useLocation();
	const locationString = String(location.pathname);
	const userId = locationString.replace('/user-details/', '');

	let error = '';

	const [values, setValues] = useState({
		loading: false,
		success: true,
		error: '',
		user: {},
	});

	const loadUser = async () => {
		setValues({ ...values, loading: true });
		await APIget(`users/${userId}`, '').then((response) => {
			console.log('User load result:');
			console.log(response);
			if (response.error) {
				return setValues({
					...values,
					loading: false,
					success: false,
					error: response.error,
				});
			}
			if (response.data.success) {
				setValues({ ...values, loading: false, user: response.data.data });
			}

			error = values.error;

			if (values.error.includes('')) {
				return (error = 'Could find not the user you requested');
			}
		});
	};

	useEffect(() => {
		loadUser();
	}, []);

	const isLoggedIn = () => {
		let user = true;
		if (values.user.id === undefined) user = false;
		return user;
	};

	const isFacebook = () => {
		let facebook = false;
		if (values.user.socialMedia !== undefined)
			if (values.user.socialMedia.facebook !== undefined) facebook = true;
		return facebook;
	};

	const isTwitter = () => {
		let twitter = false;
		if (values.user.socialMedia !== undefined)
			if (values.user.socialMedia.twitter !== undefined) twitter = true;
		return twitter;
	};

	const isLinkedIn = () => {
		let linkedIn = false;
		if (values.user.socialMedia !== undefined)
			if (values.user.socialMedia.linkedIn !== undefined) linkedIn = true;
		return linkedIn;
	};

	const isPinterest = () => {
		let pinterest = false;
		if (values.user.socialMedia !== undefined)
			if (values.user.socialMedia.pinterest !== undefined) pinterest = true;
		return pinterest;
	};

	const isInstagram = () => {
		let instagram = false;
		if (values.user.socialMedia !== undefined)
			if (values.user.socialMedia.instagram !== undefined) instagram = true;
		return instagram;
	};
	const showSocials = () => {
		let value = false;

		if (
			isFacebook() ||
			isInstagram() ||
			isLinkedIn() ||
			isPinterest() ||
			isTwitter()
		)
			value = true;
		console.log(`facebook: ${isFacebook()}`);
		console.log(`Instagram: ${isInstagram()}`);
		console.log(`LinkedIn: ${isLinkedIn()}`);
		console.log(`Pinterest: ${isPinterest()}`);
		console.log(`Twitter: ${isTwitter()}`);
		console.log(value);
		return value;
	};

	const activateAccount = async () => {
		await APIput(`users/activate/${values.user.id}`).then((response) => {
			if (response.error)
				return setValues({ ...setValues, error: response.error });

			alert('User Activation Successful');
			if (typeof window !== 'undefined') window.location.reload();
		});
	};

	const canActivate = () => {
		console.log('Checking Role');
		const lsUser = JSON.parse(localStorage.getItem('user'));
		let can = false;
		if (lsUser) {
			console.log(`User : `);
			console.log(lsUser.loggedInUser.role);
			if (lsUser.loggedInUser.role === 'ADM') can = true;
		}

		if (values.user.membershipStatus.active) return false;
		return can;
	};

	const accActive = () => {
		if (values.user.membershipStatus.active) return true;

		return false;
	};

	const isUser = () => {
		const lsUser = JSON.parse(localStorage.getItem('user'));
		let can = false;
		if (lsUser) {
			if (lsUser.loggedInUser._id === userId) can = true;
		}
		return can;
	};

	const occupation = () => {
		console.log(`length of occupation : ${values.user.occupation.length}`);
		if (values.user.occupation.length !== 0 || undefined) return true;
		return false;
	};

	console.log(values);

	const OpenDoc = ({ doc }) => {
		console.log(`Opening Doc: ${doc}`);
		return window.open(doc);
	};

	return (
		<div className="main-blog-area pd-top-120 pd-bottom-90">
			<div className="container">
				{values.loading ? (
					<Loading loadingText="Fetching User Details" />
				) : isLoggedIn() ? (
					<div>
						<div className="team-details-page">
							<div className="row">
								<div className="col-lg-5">
									<div className="thumb">
										<img
											src={usrimgsrc + values.user.photo}
											alt="img"
										/>
									</div>
									{isUser() && (
										<Link to={`/upload/users/photo/${values.user._id}`}>
											<button className="btn w-20 mt-5">
												Upload Profile Photo
											</button>
										</Link>
									)}
								</div>

								<div className="col-lg-7 align-self-center mt-5 mt-lg-0">
									{canActivate() && (
										<a>
											<button
												onClick={activateAccount}
												className="btn btn-base w-100 mb-5">
												Activate Account
											</button>
										</a>
									)}

									{accActive() && (
										<a className="align-self-center">
											<button className="btn btn-base w-100 mb-3">
												Account Active
											</button>
											<a> Expiring: </a>{' '}
											<h5 className="mb-5">
												{new Date(
													values.user.membershipStatus.expires
												).toDateString()}
											</h5>
										</a>
									)}
									<div className="details">
										<h3>
											<span>
												{values.user.name} {values.user.surname}
												{isUser() && (
													<Link
														className="userAcc"
														to={`/modify/account/${values.user._id}`}
														aria-hidden="true">
														<i className="  fa fa-pencil ml-3" />
													</Link>
												)}
											</span>
										</h3>
										<span className="designation">{values.user.field}</span>
										{showSocials() && (
											<ul className="social-media style-base pt-2 mb-4">
												<li>
													{isFacebook() && (
														<a href={values.user.socialMedia.facebook}>
															<i
																className="fa fa-facebook"
																aria-hidden="true"
															/>
														</a>
													)}
												</li>
												<li>
													{isTwitter() && (
														<a href={values.user.socialMedia.twitter}>
															<i
																className="fa fa-twitter"
																aria-hidden="true"
															/>
														</a>
													)}
												</li>
												<li>
													{isInstagram() && (
														<a href={values.user.socialMedia.instagram}>
															<i
																className="fa fa-instagram"
																aria-hidden="true"
															/>
														</a>
													)}
												</li>
												<li>
													{isLinkedIn() && (
														<a href={values.user.socialMedia.linkedIn}>
															<i
																className="fa fa-linkedin"
																aria-hidden="true"
															/>
														</a>
													)}
												</li>
												<li>
													{isPinterest() && (
														<a href={values.user.socialMedia.pinterest}>
															<i
																className="fa fa-pinterest"
																aria-hidden="true"
															/>
														</a>
													)}
												</li>
											</ul>
										)}

										<span>{values.user.socialMedia.website}</span>
										<span>{values.user.email}</span>
										<span>{values.user.phone}</span>

										{/* <p className="mt-4">
											Waltz, bad nymph, for quick jigs vex! Fox nymphs grab
											quick-jived waltz. Brick quiz whangs jumpy veldt quiz
											graced by fox whelps. Bawds jog, flick quartz, vex nymphs.
											Waltz, bad nymph, for quick jigs vex! Fox{' '}
										</p> */}
									</div>
								</div>
							</div>
							<div className="details-inner mt-4 pt-xl-3">
								<h4>Certification</h4>
								{!occupation() && <p>No certification to show</p>}
								{occupation() &&
									values.user.certificates.map((qual) => (
										<div key={qual.name}>
											<h6 className="mt-4">{qual.name}</h6>
											<span>{new Date(qual.year).toDateString()}</span>
											<a
												className="mt-3"
												target="_blank"
												href={`${userdoc}/${qual.document}`}>
												View Certificate
											</a>
										</div>
									))}
								{isUser() && (
									<Link to={`/upload/users/doc/${values.user._id}`}>
										<button className="btn  w-20 mt-5">Add Certicates</button>
									</Link>
								)}
							</div>
							<div className="details-inner mt-4 pt-xl-3">
								<h4>Occupation</h4>
								{!occupation() && <p>No occupation details to show</p>}
								{occupation() &&
									values.user.occupation.map((occ, i) => (
										<div key={i}>
											<h6 className="mt-4">
												{occ.role} {occ.company}
											</h6>
											<span>
												{occ.dateStart} - {occ.dateEnd}
											</span>
											<p className="mt-3">{occ.description}</p>
										</div>
									))}
								{isUser() && (
									<Link to={`/modify/occupation/${values.user._id}`}>
										<button className="btn w-20 mt-5">
											Add Occupation Info
										</button>
									</Link>
								)}
							</div>
						</div>
					</div>
				) : (
					<Notify message={'No User to show.'} />
				)}
			</div>
		</div>
	);
};

export default UserDetails;
