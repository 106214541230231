import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
if (user) console.log(user.token);
let options;
if (user) {
	// console.log(`User : ${JSON.stringify(user.loggedInUser)}`);
	options = {
		headers: {
			Authorization: `Bearer ${user.token}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	};
}

export const APIpost = (route, body) => {
	console.log(body);
	console.log(route);

	return axios
		.post(`${process.env.REACT_APP_API_PORT}/${route}/`, body, options)
		.then((response) => {
			// console.log(`APIpost Response: ${JSON.stringify(response)}`);
			return response;
		})
		.catch((error) => {
			console.log(`This is the error: ${error}`);
			if (error.message === 'Network Error') {
				console.log('Error is Network');
				let error = 'Network Error';
				return error;
			}
			return error.response.data;
		});
};

export const APIputjson = (route, body) => {
	console.log(body);

	return axios
		.put(`${process.env.REACT_APP_API_PORT}/${route}/`, body, options)
		.then((response) => {
			// console.log(`APIpost Response: ${JSON.stringify(response)}`);
			return response;
		})
		.catch((error) => {
			console.log(`This is the error: ${error}`);
			if (error.message === 'Network Error') {
				console.log('Error is Network');
				let error = 'Network Error';
				return error;
			}
			return error.response.data;
		});
};

export const APIput = (route, body) => {
	return axios
		.put(`${process.env.REACT_APP_API_PORT}/${route}/`, body, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${user.token}`,
			},
		})
		.then((response) => {
			// console.log(`APIpost Response: ${JSON.stringify(response)}`);
			return response;
		})
		.catch((error) => {
			console.log(`This is the error: ${error}`);
			if (error.message === 'Network Error') {
				console.log('Error is Network');
				let error = 'Network Error';
				return error;
			}
			return error.response.data;
		});
};

export const APIget = (route, filter) => {
	return axios
		.get(`${process.env.REACT_APP_API_PORT}/${route}/?${filter}`, options)
		.then((response) => {
			// console.log(`APIget Response: ${JSON.stringify(response)}`);
			return response;
		})
		.catch((error) => {
			console.log(`This is the error: ${error}`);
			if (error.message === 'Network Error') {
				console.log('Error is Network');
				let response = { error: 'Network Error' };
				return response;
			}
			if (error.message.includes('status code 404')) {
				console.log('API down');
				let response = { error: 'Connection Error' };
				return response;
			}
			return error.response.data;
		});
};
