import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Price extends Component {
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/';

		return (
			<div
				className="pricing-area pd-top-280 pd-bottom-90 text-center"
				style={{
					backgroundImage: 'url(' + publicUrl + 'assets/img/bg/pricing-bg.png)',
				}}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-6 col-lg-7 col-md-11">
							<div className="section-title">
								<h6 className="sub-title double-line">LET’S WORK</h6>
								<h2 className="title">Pricing Plans</h2>
							</div>
						</div>
					</div>
					<div className="row justify-content-center">
						{/* <div className="col-lg-4 col-md-6">
							<div className="single-pricing-inner-wrap">
								<div className="single-pricing-inner">
									<h6 className="title">STARTER</h6>
									<div className="price-area">
										<span>$12</span>/ Year
									</div>
									<ul className="pricing-list">
										<li>
											<i className="fa fa-check" />
											Bootcamps
										</li>
										<li>
											<i className="fa fa-check" />
											Job Opportunities
										</li>
										<li className="unable">
											<i className="fa fa-times" />
											Bootcamp Discount
										</li>
										<li className="unable">
											<i className="fa fa-times" />
											On-Site Tours
										</li>
										<li className="unable">
											<i className="fa fa-times" />
											Job Shadowing
										</li>
									</ul>
									<Link
										className="btn btn-base btn-radius w-100"
										to="#">
										GET STARTED
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-pricing-inner-wrap">
								<div className="single-pricing-inner">
									<h6 className="title">BASIC</h6>
									<div className="price-area">
										<span>$15</span>/ Year
									</div>
									<ul className="pricing-list">
										<li>
											<i className="fa fa-check" />
											Bootcamps
										</li>
										<li>
											<i className="fa fa-check" />
											Job Opportunities
										</li>
										<li>
											<i className="fa fa-check" />
											Bootcamp Discount
										</li>
										<li className="unable">
											<i className="fa fa-times" />
											On-Site Tours
										</li>
										<li className="unable">
											<i className="fa fa-times" />
											Job Shadowing
										</li>
									</ul>
									<Link
										className="btn btn-base btn-radius w-100"
										to="#">
										GET STARTED
									</Link>
								</div>
							</div>
						</div> */}
						<div className="col-lg-4 col-md-6">
							<div className="single-pricing-inner-wrap">
								<div className="single-pricing-inner">
									<h6 className="title">BASIC</h6>
									<div className="price-area">
										<span>$12</span>/ Year
									</div>
									<ul className="pricing-list">
										<li>
											<i className="fa fa-check" />
											Bootcamps
										</li>
										<li>
											<i className="fa fa-check" />
											Job Opportunities
										</li>
										<li>
											<i className="fa fa-check" />
											Bootcamp Discount
										</li>
										<li>
											<i className="fa fa-check" />
											On-Site Tours
										</li>
										<li>
											<i className="fa fa-check" />
											Job Shadowing
										</li>
									</ul>
									<Link
										className="btn btn-base btn-radius w-100"
										to="">
										GET STARTED
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-5">
						<h3>How To Pay</h3>
						<h6 className="mt-5">1. Make an Ecocash transaction to:</h6>
						<p>+263 779 167 004</p>
						<h6 className="mt-5">
							2. Send Reference Number to our Whatsapp Business Number: +263 779
							167 004
						</h6>
						<h6 className="mt-5">
							3. Wait for our team to verify and you are all set
						</h6>
					</div>
				</div>
			</div>
		);
	}
}

export default Price;
