import React from 'react';
import Navbar from './global-components/navbar-v1';
import PageHeader from './global-components/page-header';
import User from './section-components/user-details';
import Footer from './global-components/footer-v2';

const UserPage = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="User Details" />
			<User />
			<Footer />
		</div>
	);
};

export default UserPage;
