import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { APIget } from '../../apiCore';
import moment from 'moment';

const Event = () => {
	let publicUrl = process.env.PUBLIC_URL + '/';

	const [values, setValues] = useState({
		bootcamp: {},
		error: '',
		attending: false,
		success: false,
		loading: true,
	});

	const loadUpcomingEvents = async () => {
		setValues({ ...values, loading: true });

		// set up for getting upcoming events only
		const epochTimeNow = moment(new Date()).valueOf();

		const qstr = 'limit=3';
		await APIget('bootcamps', qstr).then((data) => {
			console.log('Data recieved from bootcamps');
			console.log(data.data);
			if (data.error)
				return setValues({ ...values, error: data.error, success: false });
			if (data.data.count !== 0) {
				setValues({
					...values,
					bootcamp: data.data.data,
					loading: false,
				});
			} else {
				setValues({
					...values,
					loading: false,
				});
			}
		});
	};

	const upComing = () => {
		if (values.bootcamp.length !== undefined || 0) {
			values.bootcamp.map((boot) => {
				console.log(boot);
			});
			return true;
		}

		return false;
	};
	console.log(values);
	// values.bootcamp.map((boot) => {
	// 	console.log(boot);
	// });

	useEffect(() => {
		loadUpcomingEvents();
	}, []);

	return (
		<div className="events-area pd-top-110 pd-bottom-120 go-top">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-6 col-lg-7 col-md-11">
						<div className="section-title text-center">
							<h6 className="sub-title double-line">Events</h6>
							<h2 className="title">Upcoming Events</h2>
						</div>
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-lg-8">
						<ul
							className="single-blog-list-wrap style-white"
							style={{ backgroundColor: 'var(--heading-color)' }}>
							{upComing() ? (
								values.bootcamp.map((boot) => (
									<li key={boot.name}>
										<div className="media single-blog-list-inner style-white">
											{/* <div className="media-left date">
												<span>JAN</span>
												<br />
												{boot.dateStart.split('-')[0]}
											</div> */}
											<div className="media-body details">
												<ul className="blog-meta">
													<li>
														<i className="fa fa-user" /> {boot.trainer.name}
													</li>
												</ul>
												<h5>
													<Link to={`/bootcamp/${boot.slug}`}>{boot.name}</Link>
												</h5>
											</div>
										</div>
									</li>
								))
							) : (
								<div className="media single-blog-list-inner style-white">
									<div className="media-body details">
										<h5 className="title">No Upcoming events to show</h5>
									</div>
								</div>
							)}
						</ul>
					</div>
					<div className="col-lg-4 align-self-center">
						<div className="event-thumb">
							<img
								src={publicUrl + 'assets/img/other/events.png'}
								alt="img"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Event;
