import React from 'react';
import BounceLoader from 'react-spinners/BounceLoader';

export const Loading = ({ loadingText }) => {
	return (
		<div>
			<div className="center mb-4">
				{/* <div className="center mb-4">
					<BounceLoader
						color={'#030059'}
						size={50}
					/>
				</div> */}

				<div className="center mb-4">
					<h3> {loadingText}...</h3>
				</div>
			</div>
		</div>
	);
};

export const Notify = ({ message }) => {
	return (
		<div className=" mb-4">
			{/* <div className="center mb-4">
				<BounceLoader
					color={'#030059'}
					size={50}
				/>
			</div> */}

			<div className=" mb-4">
				<h5> {message}</h5>
			</div>
		</div>
	);
};
