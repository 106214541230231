import React from 'react';
import Navbar from './global-components/navbar-v1';
import PageHeader from './global-components/page-header';
import CreateJob from './section-components/createJob';
import Footer from './global-components/footer-v2';

const createJob = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="Create Job" />
			<CreateJob />
			<Footer />
		</div>
	);
};

export default createJob;
