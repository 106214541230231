import React, { useState, useEffect } from 'react';
import { APIputjson, APIget } from '../../apiCore';
import { Link, Redirect, useLocation } from 'react-router-dom';

const Modify = () => {
	const navLocation = useLocation();
	const locationString = String(navLocation.pathname);
	const navLocationSplit = locationString.split('/');

	const userId = navLocationSplit[3];
	const modType = navLocationSplit[2];

	const [values, setValues] = useState({
		occupation: [],
		interests: [],
		occupationCompany: '',
		occupationRole: '',
		occupationDescription: '',
		occupationStartDate: '',
		occupationEndDate: '',
		occupationReferrenceName: '',
		occupationReferrenceContact: '',
		user_id: '',
		name: '',
		surname: '',
		dob: '',
		profession: '',
		address: '',
		location: '',
		interestsStr: '',
		field: 'Science',
		gender: 'Male',
		facebook: '',
		twitter: '',
		instagram: '',
		linkedIn: '',
		website: '',
		error: '',
		success: false,
		loading: false,
		redirectToReferrer: false,
	});

	const handleChange = (name) => (e) => {
		setValues({ ...values, error: false, [name]: e.target.value });
	};

	useEffect(() => {
		loadUserInfo();
	}, []);

	const loadUserInfo = async () => {
		setValues({ ...values, loading: true });
		await APIget(`users/${userId}`, '').then((response) => {
			console.log('User load result:');
			console.log(response);
			if (response.error) {
				return setValues({
					...values,
					loading: false,
					success: false,

					error: response.error,
				});
			}
			if (response.data.success) {
				let user = response.data.data;
				setValues({
					...values,
					loading: false,
					user_id: user.id,
					name: user.name,
					surname: user.surname,
					dob: user.dob,
					gender: user.gender,
					phone: user.phone,
					address: user.address,
					location: user.location,
					gender: user.gender,
					profession: user.profession,
					field: user.field,
					interests: user.interests,
					interestsStr: user.interests.toString(),
					facebook: user.socialMedia.facebook,
					linkedIn: user.socialMedia.linkedIn,
					twitter: user.socialMedia.twitter,
					instagram: user.socialMedia.instagram,
					website: user.socialMedia.website,
					occupation: user.occupation,
				});
			}
		});
	};

	let {
		user_id,
		name,
		surname,
		profession,
		field,
		gender,
		dob,
		phone,
		address,
		location,
		interestsStr,
		interests,
		facebook,
		instagram,
		linkedIn,
		twitter,
		website,
		occupationCompany,
		occupationRole,
		occupationDescription,
		occupationStartDate,
		occupationEndDate,
		occupationReferrenceName,
		occupationReferrenceContact,
		occupation,
		error,
		success,
		loading,
		redirectToReferrer,
	} = values;

	console.log(values);

	const clickSubmit = async (event) => {
		event.preventDefault();

		setValues({ ...values, loading: true });

		interests = interestsStr.split(',');

		const socialMedia = { facebook, twitter, instagram, website, linkedIn };

		console.log(`Occupation: `);
		console.log(occupation);
		occupation = [
			...occupation,
			{
				company: occupationCompany,
				role: occupationRole,
				description: occupationDescription,
				dateStart: occupationStartDate,
				dateEnd: occupationEndDate,
				ref: {
					name: occupationReferrenceName,
					contact: occupationReferrenceContact,
				},
			},
		];
		console.log(`Occupation: `);
		console.log(occupation);

		await APIputjson(`users/${user_id}`, {
			name,
			surname,
			profession,
			field,
			occupation,
			interests,
			dob,
			gender,
			phone,
			address,
			location,
			socialMedia,
		}).then((data) => {
			console.log(data);
			if (data.error) {
				return setValues({ ...values, error: data.error, success: false });
			}

			setValues({
				...values,
				success: true,
				message: 'User details modified',
				occupationCompany: '',
				occupationRole: '',
				occupationDescription: '',
				occupationStartDate: '',
				occupationEndDate: '',
				occupationReferrenceName: '',
				occupationReferrenceContact: '',
			});
		});

		// console.log(response);

		// console.log(`Making a request to: ${process.env.REACT_APP_API_PORT}`);
	};

	const showError = () => (
		<div
			className="alert alert-danger center"
			style={{ display: values.error ? '' : 'none' }}>
			{values.error}
			{'       '}
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
		</div>
	);

	const close = () => {
		setValues({ ...values, success: false, error: '' });
	};

	const showSuccess = () => (
		<div
			style={{ display: values.success ? '' : 'none' }}
			className="alert alert-info center">
			{values.message}
			{'       '}
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
		</div>
	);

	const showLoading = () =>
		loading && (
			<div style={{ display: success ? '' : 'none' }}>
				<div className="alert alert-info center">Modifying User...</div>
			</div>
		);

	const isAccountMod = () => {
		if (modType === 'account') return true;
		return false;
	};

	return (
		<div className="signup-page-area pd-top-120 pd-bottom-120">
			{showLoading()}
			{showSuccess()}
			{showError()}
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-6 col-lg-7">
						{isAccountMod() ? (
							<form className="signin-inner">
								<div className="row">
									Name
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('name')}
												type="text"
												placeholder="First Names"
												value={name || ''}
											/>
										</div>
									</div>
									Surname
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('surname')}
												type="text"
												placeholder="Last Name"
												value={surname || ''}
											/>
										</div>
									</div>
									<div className="col-12  single-input-inner style-bg-border">
										<select
											onChange={handleChange('gender')}
											name="gender"
											id="gender">
											<option value="Male">Male</option>
											<option value="Female">Female</option>
										</select>
									</div>
									Date Of Birth
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('dob')}
												type="date"
												id="birthday"
												name="birthday"
												value={dob || ''}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('phone')}
												type="tel"
												placeholder="Phone Number (777 777 777)"
												value={phone || ''}
											/>
										</div>
									</div>
									Address
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('address')}
												type="text"
												placeholder="Address"
												value={address || ''}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('location')}
												type="text"
												placeholder="City"
												value={location || ''}
											/>
										</div>
									</div>
									Profession
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('profession')}
												type="text"
												placeholder="Profession"
												value={profession || ''}
											/>
										</div>
									</div>
									Field
									<div className="col-12  single-input-inner style-bg-border">
										<select
											onChange={handleChange('field')}
											name="field"
											id="field">
											<option value="science">Science</option>
											<option value="technology">Technology</option>
											<option value="engineering">Engineering</option>
											<option value="mathematics">Mathematics</option>
										</select>
									</div>
									Job Interests
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('interestsStr')}
												type="text"
												placeholder="Job Interests. List and seperate using 'comma'"
												value={interestsStr || ''}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<i
												className="fa fa-facebook"
												aria-hidden="true"
											/>
											<input
												onChange={handleChange('facebook')}
												type="text"
												placeholder="Facebook Link"
												value={facebook || ''}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<i
												className="fa fa-linkedin"
												aria-hidden="true"
											/>
											<input
												onChange={handleChange('linkedIn')}
												type="text"
												placeholder="LinkedIn Link"
												value={linkedIn || ''}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<i
												className="fa fa-twitter"
												aria-hidden="true"
											/>
											<input
												onChange={handleChange('twitter')}
												type="text"
												placeholder="Twitter Link"
												value={twitter || ''}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<i
												className="fa fa-instagram"
												aria-hidden="true"
											/>
											<input
												onChange={handleChange('instagram')}
												type="text"
												placeholder="Instagram Link"
												value={instagram || ''}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<i
												className="fa fa-globe"
												aria-hidden="true"
											/>
											<input
												onChange={handleChange('website')}
												type="text"
												placeholder="Website"
												value={website || ''}
											/>
										</div>
									</div>
									<div className="col-12 mb-4">
										<button
											onClick={clickSubmit}
											className="btn btn-base w-100">
											Update Account Information
										</button>
									</div>
								</div>
							</form>
						) : (
							<form className="signin-inner">
								<div className="row">
									Company
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('occupationCompany')}
												type="text"
												placeholder="Company Name"
												value={occupationCompany}
											/>
										</div>
									</div>
									Role
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('occupationRole')}
												type="text"
												placeholder="What was your role?"
												value={occupationRole}
											/>
										</div>
									</div>
									Description Of Role
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<textarea
												onChange={handleChange('occupationDescription')}
												type="text"
												placeholder="What did you do there?"
												value={occupationDescription}
											/>
										</div>
									</div>
									Start Date
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('occupationStartDate')}
												type="date"
												id="occupationStartDate"
												name="occupationStartDate"
												value={occupationStartDate}
											/>
										</div>
									</div>
									End Date
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('occupationEndDate')}
												type="date"
												id="occupationEndDate"
												name="occupationEndDate"
												value={occupationEndDate}
											/>
										</div>
									</div>
									Referrence
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('occupationReferrenceName')}
												type="text"
												placeholder="Who can we contact to verify?"
												value={occupationReferrenceName}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('occupationReferrenceContact')}
												type="text"
												placeholder="What is their email/phone number we can use?"
												value={occupationReferrenceContact}
											/>
										</div>
									</div>
									<div className="col-12 mb-4">
										<button
											onClick={clickSubmit}
											className="btn btn-base w-100">
											Update Occupation Information
										</button>
									</div>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Modify;
