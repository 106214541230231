import React, { useState } from 'react';
import { APIput } from '../../apiCore';
import { Link, Redirect, useLocation } from 'react-router-dom';
// const multer = require('multer');

// const upload = multer({ dest: 'uploads/' });

const Upload = () => {
	const location = useLocation();
	const locationString = String(location.pathname);
	const slug = locationString.split('/');
	console.log('Address: ');
	console.log(slug);
	console.log(slug[slug.length - 2]);
	// console.log(`API Location: ${process.env.REACT_APP_API_PORT}`);
	const [image, setImage] = useState('');
	const [doc, setDoc] = useState('');
	const [values, setValues] = useState({
		description: '',
		name: '',
		date: '',
		error: '',
		successMessage: '',
		success: false,
		loading: false,
		redirectToReferrer: false,
	});

	let { name, description, date } = values;

	const handleImageChange = (e) => {
		console.log(e.target.files);
		setImage(e.target.files[0]);
		setValues({ ...values, error: false, loading: true });
	};

	const handleDocChange = (e) => {
		console.log(e.target.files);
		setDoc(e.target.files[0]);
		setValues({ ...values, error: false, loading: true });
	};

	const handleChange = (name) => (e) => {
		setValues({ ...values, error: false, [name]: e.target.value });
	};

	let { error, successMessage, success, loading, redirectToReferrer } = values;

	// console.log(values);

	const clickSubmit = async (event) => {
		event.preventDefault();
		setValues({ ...values, loading: true });
		const formData = new FormData();
		formData.append('image', image);
		formData.append('doc', doc);
		formData.append('name', values.name);
		formData.append('year', values.date);
		formData.append('description', values.description);
		// console.log(req);
		await APIput(`${slug[2]}/${slug[4]}/${slug[3]}`, formData).then(
			(response) => {
				console.log(response);
				if (response.error) {
					return setValues({
						...values,
						error: response.error,
						success: false,
					});
				}

				setValues({
					...values,
					loading: false,
					success: true,
					successMessage: 'Image uploaded successfully',
					redirectToReferrer: true,
				});

				// setValues({
				// 	...values,
				// 	jobId: response.data.data._id,
				// 	successMessage: response.data.message,
				// 	success: true,
				// 	loading: false,
				// 	redirectToReferrer: true,
				// });
			}
		);
	};

	const showError = () => (
		<div
			className="alert alert-danger center"
			style={{ display: error ? '' : 'none' }}>
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{error}
		</div>
	);

	const photoUpload = () => {
		if (slug[3] === 'photo') return true;
		return false;
	};

	const close = () => {
		setValues({ ...values, success: false, error: '' });
	};

	const showSuccess = () => (
		<div
			style={{ display: success ? '' : 'none' }}
			className="alert alert-info center">
			<i
				className="fa fa-times-circle"
				onClick={close}></i>
			{successMessage}
		</div>
	);

	const showLoading = () =>
		loading && (
			<div
				className="alert alert-info center"
				style={{ display: success ? '' : 'none' }}>
				<div>Creating New Job...</div>
			</div>
		);

	const redirectUser = () => {
		if (redirectToReferrer) {
			return <Redirect to={`/user-details/${slug[4]}`} />;
		}
	};

	console.log(values);

	return (
		<div className="signup-page-area pd-top-120 pd-bottom-120">
			<div className="container">
				<div className="row justify-content-center">
					{showLoading()}
					{showSuccess()}
					{showError()}
					{redirectUser()}
					<div className="col-xl-6 col-lg-7">
						<form className="signin-inner">
							{photoUpload() ? (
								<div className="row">
									<div className="col-12 mt-3">
										<input
											className=" single-input-inner style-bg-border"
											onChange={handleImageChange}
											type="file"
											name="image"
											accept="image/*"
										/>
									</div>
									<div className="col-12 mb-4">
										<button
											onClick={clickSubmit}
											className="btn btn-base w-100">
											Upload Image
										</button>
									</div>
								</div>
							) : (
								<div className="row">
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('name')}
												type="text"
												placeholder="Certificate Name"
												value={name || ''}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<textarea
												onChange={handleChange('description')}
												type="text"
												placeholder="Certificate description"
												value={description || ''}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="single-input-inner style-bg-border">
											<input
												onChange={handleChange('date')}
												type="date"
												id="dateStart"
												name="dateStart"
												placeholder="Year Attained"
												value={date}
											/>
										</div>
									</div>
									<div className="col-12 mt-3">
										<input
											className=" single-input-inner style-bg-border"
											onChange={handleDocChange}
											type="file"
											name="image"
										/>
									</div>
									<div className="col-12 mb-4">
										<button
											onClick={clickSubmit}
											className="btn btn-base w-100">
											Upload Certification
										</button>
									</div>
								</div>
							)}
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Upload;
