import React from 'react';
import Navbar from './global-components/navbar-v1';
import PageHeader from './global-components/page-header';
import CreateBootcamp from './section-components/createBootcamp';
import Footer from './global-components/footer-v2';

const createBootcamp = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="Create Event" />
			<CreateBootcamp />
			<Footer />
		</div>
	);
};

export default createBootcamp;
