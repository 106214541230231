import React from 'react';
import Navbar from './global-components/navbar-v1';
import PageHeader from './global-components/page-header';
import About from './section-components/about';
import FunFact from './section-components/fun-fact';
import Team from './section-components/team-v3';
import SpecialArea from './section-components/special-area';
import Testimonial from './section-components/testimonial';
import Event from './section-components/event';
import Footer from './global-components/footer-v2';

const AboutPage = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="About" />
			<About sectionclass="pd-bottom-120" />
			<FunFact />
			<Team />
			{/* <SpecialArea /> */}
			{/* <Testimonial /> */}
			{/* <Event margintop="200" /> */}
			<Footer />
		</div>
	);
};

export default AboutPage;
