import React from 'react';
import Navbar from './global-components/navbar-v1';
import PageHeader from './global-components/page-header';
import UsersPage from './section-components/users';
import Funfact from './section-components/fun-fact';
import Testimonial from './section-components/testimonial';
import Footer from './global-components/footer-v2';

const Users = () => {
	return (
		<div>
			<Navbar />
			<PageHeader headertitle="Users" />
			<UsersPage />
			<Funfact />
			<Testimonial />
			<Footer />
		</div>
	);
};

export default Users;
