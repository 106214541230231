import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { APIget } from '../../apiCore';
import UsersCard from './usersCard';
import { Notify, Loading } from '../global-components/messaging';

const User = () => {
	let publicUrl = process.env.PUBLIC_URL + '/';

	const [values, setValues] = useState({
		search: '',
		loading: false,
		error: '',
		userRole: '',
		Users: {},
		pagination: {},
		success: true,
	});

	const loadUser = async (page) => {
		console.log('Searching for Users');

		setValues({ ...values, loading: true });
		console.log(`Query String from Loading:  ${formQueryStr()}`);
		console.log();

		await APIget('Users', formQueryStr(page)).then((response) => {
			if (response.error)
				return setValues({ ...values, error: response.error, success: false });
			setValues({
				...values,
				loading: false,
				Users: response.data.data,
				pagination: response.data.pagination,
			});
		});
	};

	const formQueryStr = (page) => {
		let queryStr = '';

		queryStr = queryStr.concat('&', `page=${page || 1}`);

		console.log(`Search: ${values.search},    User role: ${values.userRole}`);

		if (values.search !== '')
			queryStr = queryStr.concat('&', `search=${values.search}`);

		if (values.userRole !== '')
			queryStr = queryStr.concat('&', `role=${values.userRole}`);

		queryStr = queryStr.concat('&', 'limit=6');

		console.log(`Query String: ${queryStr}`);

		return queryStr;
	};

	const isUsers = () => {
		let Users = false;
		if (values.Users.length !== undefined || 0) Users = true;
		return Users;
	};

	const handleSearchButton = () => {
		loadUser();
	};

	useEffect(() => {
		loadUser();
	}, []);

	const handleLastPage = () => {
		console.log(values.pagination.totalPages);
		setValues({ ...values, page: values.pagination.totalPages });
		loadUser(values.pagination.totalPages);
	};

	const handleNext = () => {
		if (values.pagination.next.page >= values.pagination.currentpage)
			loadUser(values.pagination.next.page);
	};

	const handlePrev = () => {
		console.log('Previous page');
		if (values.pagination.prev.page <= values.pagination.currentpage) {
			console.log('Able to go to Previous page');
			loadUser(values.pagination.prev.page);
		}
	};

	// 	const onUserModeChange = () => {
	// setValues({ ...values, [name]: e.target.value });
	// 	}

	const handleChange = (name) => async (e) => {
		await setValues({ ...values, error: false, [name]: e.target.value });

		// loadUser();
	};

	console.log(values);

	return (
		<div className="team-area pd-top-120 pd-bottom-70 go-top">
			<div className="container">
				<form className="footer-subscribe-inner">
					<div className="row">
						<div className="col-lg-8">
							<div className="single-input-inner style-border-bottom">
								<input
									name="search"
									onChange={handleChange('search')}
									type="text"
									placeholder="Name of User"
								/>
							</div>
						</div>
						<div className="col-lg-2">
							<div className="single-input-inner style-border-bottom">
								<select
									onChange={handleChange('userRole')}
									className=" btn  w-100"
									name="field"
									id="field"
									value={values.userRole}>
									<option value="">All</option>
									<option value="TRN">Trainer</option>
									<option value="USER">User</option>
									<option value="ADM">Admin</option>
								</select>
							</div>
						</div>

						<div className="col-lg-2">
							<a
								onClick={handleSearchButton}
								className="btn btn-base w-100">
								Search
							</a>
						</div>
					</div>
				</form>
				<div className="row justify-content-center mt-5">
					{values.loading ? (
						<Loading loadingText="Checking for Users" />
					) : isUsers() ? (
						values.Users.map((user, i) => (
							<UsersCard
								key={i}
								user={user}
							/>
						))
					) : (
						<Notify message="No Users to Show" />
					)}
				</div>
				{isUsers() && (
					<nav className="td-page-navigation center">
						<ul className="pagination">
							<li className="pagination-arrow">
								<button
									className="btn-pgn"
									onClick={handlePrev}>
									<i className="fa fa-angle-double-left" />
								</button>
							</li>
							<li>
								<button className="btn-pgn">
									{values.pagination.currentpage}
								</button>
							</li>
							<li>
								<button className="btn-pgn-uslss">/</button>
							</li>
							<li>
								<button
									className="btn-pgn"
									onClick={handleLastPage}>
									{values.pagination.totalPages}
								</button>
							</li>
							<li className="pagination-arrow">
								<button
									className="btn-pgn"
									onClick={handleNext}>
									<i className="fa fa-angle-double-right" />
								</button>
							</li>
						</ul>
					</nav>
				)}
			</div>
		</div>
	);
};

export default User;
